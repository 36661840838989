<template>
<div class="row p-h-md p-v-md">
    <!-- Loading -->
    <div v-show="loading" style="width: 100%; text-align: center;">
        <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
            <span class="visually-hidden">{{ $t('events.loading') }}...</span>
        </div>
    </div>
    <div v-show="!loading">
        <h4 class="sub-heading"> {{ $t('sites.data.add_new_brand') }}</h4>
        <!-- Add New Brand -->
        <div class="col-12">
            <div class="card">
                <div class="card-body row">
                    <div class="col-4 m-r-md">
                        <label for="filename" class="form-label"> {{$t('sites.data.brand')}} </label>
                        <select v-show="!loadingExisting" v-model="selected_brand" class="form-select">
                            <option value="null" selected disabled> Select a Brand </option>
                            <option v-for="brand in allBrands" :key="brand.id" :value="brand.id"> {{brand.name}} </option> 
                        </select>
                    </div>

                    <div class="flex-center m-t-lg">
                        <button class="btn btn-primary" @click="addNewBrand" :class="{'disabled': selected_brand === null}"> {{$t('sites.data.add_brand')}}</button>
                    </div>
                </div>
            </div>
        </div>

        <h4 class="sub-heading" v-show="existingBrands.length > 0"> {{$t('sites.data.brands')}} </h4>
        <!-- Existing Brands -->
        <div class="row no-m-l">
            <div class="card col-3 no-p-h no-p-v m-r-xxl" v-for="brand in existingBrands" :key="'brand-' + brand.id">
            <div class="card-body flex-center space-between">
                <div>
                    <BrandImage
                    :filename="brand.filename"
                    class="brand-image"
                    />
                </div>
                <div class="row card-body__right">
                    <span class="brand-name"> {{brand.name}} </span>
                    <div style="text-align: end">
                        <button class="btn bg-danger" @click="removeBrand(brand.id)"> Remove </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import SiteService from '../../../../services/site.service';
import BrandImage from './BrandImage.vue';

export default {
    name: 'Brands',
    props: {
        site: Object,
        client_id: Number
    },
    components: { BrandImage },
    data() {
        return {
            selected_brand: null,
            loading: true,
            loadingExisting: true,
            existingBrands: this.site.brands,
            allBrands: [],
        }
    },
    mounted() { 
        SiteService.getBrands()
        .then(response => {
            this.allBrands = response.data.brands;
            this.loading = false;
            this.loadingExisting = false;
        })
        .catch(error => {
            this.$error(this.$t('sites.data.failed_to_load_brands', error));
        })     
        console.log(this.existingBrands);
    },
    methods: {
        addNewBrand() {
            if(this.selected_brand) {
               SiteService.addNewBrand(this.$props.site.id, this.selected_brand)
               .then(response => {
                   const newBrand = this.allBrands.filter((brand) => this.selected_brand === brand.id)[0];
                   this.existingBrands.push({id: newBrand.id, name: newBrand.name, filename: newBrand.filename});
                   this.selected_brand = null;
               })
               .catch(error => {
                   this.$error(this.$t('sites.data.failed_to_add_brand'), error);
               })
            }
            return;
        }, 
        removeBrand(id) {
            SiteService.removeBrand(this.$props.site.id, id)
            .then(response => {
                this.existingBrands = this.existingBrands.filter((brand) => id !== brand.id);
            })
            .catch(error => {
                 this.$error(this.$t('sites.data.failed_to_delete_brand'), error);
            })
        }
    }
}
</script>

<style scoped>

.brand-image {
    width: 100px !important;
    height: 100px;
    border-radius: 50%;
}

.brand-name {
    font-size: 16px;
    text-transform: capitalize;
}

.card-body__right {
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
}

.card-body__right button {
   height: fit-content;
    width: fit-content;
    padding: 5px 20px;
    color: white;
}

.card-body__right button:hover {
    color: white;
}

.flex-center {
    flex-wrap: wrap;
}

@media (max-width: 1440px) {
    .col-3 {
        width: 60%
    }
}

</style>