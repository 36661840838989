<template>
  <h1> Barriers </h1>
</template>

<script>
export default {
    name: 'SiteBarriers'
}
</script>

<style>

</style>