<template>
  {{display}}
  <div class="row">
    <div class="card col-12">
      <div class="card-header">
        <h5 class="card-title"> {{$t('sites.display', {text: 'Overview'})}}</h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="siteName" class="form-label"> {{$t('sites.name')}}</label>
          <div class="row">
            <div class="col-8" style="padding-left: 0px;">
              <input type="text" id="siteName" class="form-control" v-model="siteDisplay.name">
            </div>
            <div class="col-3" style="padding-top: 4px;">
              <button class="btn btn-primary" :disabled="!displayNameUpdated" @click="updateDisplayName"> Save </button>
            </div>
            {{displayUpdated}}
          </div>
        </div>
        <div class="m-t-md main-container">

          <ul class="list-group list-group-flush" style="width: 100%">
            <li class="list-group-item cameras">
              <i v-if="siteDisplay.client !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
              <strong class="flex-fill"> {{$t('sites.devices.assigned', {text: 'Client'})}} </strong>
              <span class="float-end"> {{siteDisplay.client}}</span>
            </li>

            <li class="list-group-item cameras">
              <i v-if="siteDisplay.site !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
              <strong class="flex-fill"> {{$t('sites.devices.assigned', {text: 'Site'})}} </strong>
              <span class="float-end"> {{siteDisplay.site}}</span>
            </li>

            <li class="list-group-item cameras">
              <i v-if="siteDisplay.last_authenticated !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
              <strong class="flex-fill"> {{$t('sites.devices.last_authenticated')}} </strong>
              <span class="float-end"> {{siteDisplay.last_authenticated}}</span>
            </li>

            <li class="list-group-item cameras">
              <i v-if="siteDisplay.last_ping !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
              <strong class="flex-fill"> {{$t('sites.devices.last_ping')}} </strong>
              <span class="float-end"> {{siteDisplay.last_ping}}</span>
            </li>

            <li class="list-group-item cameras">
              <i v-if="siteDisplay.version !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
              <strong class="flex-fill"> {{$t('sites.devices.version')}} </strong>
              <span class="float-end"> {{siteDisplay.version}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card col-12" style="height: 300px;" >
      <div class="card-header">
        <h5 class="card-title"> {{$t('sites.display', {text: 'Debug Statistics'})}}</h5>
      </div>
      <div class="card-body">
        <MessageCard 
          :alertType="'indicator-danger'"
          :alertTitle="$t('global.important_message')"
          :alertText="$t('sites.devices.alert_message')"
          id="dangercard"
        />

        <ul class="list-group list-group-flush" style="width: 100%">

        </ul>
        
      </div>
    </div>

  </div>
</template>

<script>
import DisplayService from '../../../../../services/display.service';
import {DateTime} from 'luxon';
import _ from "lodash";
import MessageCard from '../../../../../components/MessageCard.vue';

export default {
    name: 'Display',
    props: {
      site: Object,
      client_id: String,
      displays: Object   //gives all displays basic infos 
    },
    components: {MessageCard},
    data() {
      return {
        siteName: "",
        siteDisplay: {},
        originalDisplay: {},
        twilioToken: null
      }
    },
    mounted() {
      this.getSiteDisplay();
      // console.log(this.user.socket_token);
      // console.log("Socket ID: ", this.$socket.client.id);
    },
    methods: {
      getSiteDisplay() {
        DisplayService.getDisplay(this.displays[0].id)
        .then(response => {
          console.log(response);
          response.data.display.cameras.forEach(camera => {
            if(camera.lastEvent) {
              camera.lastEvent.created_at = DateTime.fromSeconds(camera.lastEvent.created_at).toFormat("dd/MM/yyyy HH:mm:ss");
            }
            camera.rebootDisabled = false;  
          });
          //If not null 
          if(response.data.display.last_authenticated) {
            response.data.display.last_authenticated = DateTime.fromSeconds(response.data.display.last_authenticated).toFormat("dd/MM/yyyy HH:mm:ss");
          }

          if(response.data.display.last_ping) {
            response.data.display.last_ping = DateTime.fromSeconds(response.data.display.last_ping).toFormat("dd/MM/yyyy HH:mm:ss");
          }
          this.siteDisplay = response.data.display; //gives details about the site display with the camera, token and twilio info
          this.originalDisplay = _.cloneDeep(response.data.display);
          this.twilioToken = response.data.twilio;
        })
        .catch(error => {
          console.log(error);
        })
      },
      updateDisplayName() {
        DisplayService.updateDisplay(this.siteDisplay.id, this.siteDisplay.name)
        .then(() =>{
          this.$success(this.$t('sites.devices.display_update_success'));
          this.originalDisplay = _.cloneDeep(this.siteDisplay);
        })
        .catch(error => {
          this.$error(this.$t('sites.devices.display_update_success'), error);
        })
      }
    },
    computed: {
      displayNameUpdated: function() {
        return !(JSON.stringify(this.siteDisplay.name) === JSON.stringify(this.originalDisplay.name))
      }
    }

}
</script>

<style scoped>
  .row {
    margin: 0;
  }

  .btn.btn-primary {
    padding: 6px 20px;
  }

  /* .list-group-item {
    color: white;;
  } */
</style>