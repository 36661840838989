import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class DisplayService {
    //GET
    getDisplay(display_id) {
        return axios.get(`${API_URL}display/${display_id}`, {headers: authHeader()});
    }

    //PATCH 
    updateDisplay(display_id, name) {
        return axios.patch(`${API_URL}display/${display_id}`, {name}, {headers: authHeader()});
    }
}

export default new DisplayService();