<template>
  <div class="row m-t-xs">
    <div class="col-12">
      <div class="content-menu content-menu-left ps">
        <ul class="list-unstyled">
          <!-- Site Infromation -->
          <li> 
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.information}"
              role="tab" 
              :aria-selected="tabs.information" 
              @click="selectTab('information')"> {{$t('sites.site_information')}} </a>
          </li>
          <!-- Site Data -->

          <!-- Site Whitelist -->
          <li v-if="$can('sites-whitelist-view')">
            <a 
              href="javascript:void(0);"
              :class="{'active': tabs.whitelist}"
              role="tab"
              :aria-selected="tabs.whitelist"
              @click="selectTab('whitelist')"> {{ $t('global.whitelist')}} </a>
          </li>

          <!-- Site Barriers -->
          <!-- <li>
            <a 
              href="javascript:void(0);"
              :class="{'active': tabs.barriers}"
              role="tab"
              :aria-selected="tabs.barriers"
              @click="selectTab('barriers')"> {{ $t('global.barriers')}} </a>
          </li> -->

          <!-- Devices -->
          <!-- <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.devices}"
              role="tab" 
              :aria-selected="tabs.devices"
              @click="selectTab('devices')"> {{ $t('sites.site.devices' )}} </a>
          </li>  -->

          <li class="divider"></li>

          <!-- Site Sign Ins -->
          <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.signin}"
              role="tab" 
              :aria-selected="tabs.signin"
              @click="selectTab('signin')"> {{ $t('sites.site.sign_ins')}} </a>
          </li>

          <!-- Red Diesel -->
          <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.redDiesel}"
              role="tab" 
              :aria-selected="tabs.redDiesel"
              @click="selectTab('redDiesel')"> {{ $t('sites.site.rdiesel' )}} </a>
          </li>

          <!-- Refusal form -->
          <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.refusal}"
              role="tab" 
              :aria-selected="tabs.refusal"
              @click="selectTab('refusal')"> {{ $t('sites.site.refusal' )}} </a>
          </li>

          <!-- Temperature form -->
          <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.temperature}"
              role="tab" 
              :aria-selected="tabs.temperature"
              @click="selectTab('temperature')"> {{ $t('sites.site.temperature')}} </a>
          </li>

          <!-- Lost Property form -->
          <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.property}"
              role="tab" 
              :aria-selected="tabs.property"
              @click="selectTab('property')"> Lost Property </a>
          </li>

          <!-- Checklists -->
          <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.checklist}"
              role="tab" 
              :aria-selected="tabs.checklist"
              @click="selectTab('checklist')"> Forecourt Checklist </a>
          </li>

          <!-- Barriers -->
          <li v-if="$can('barriers-manage')" class="divider"></li>
          <li v-if="$can('barriers-manage')">
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.barriers}"
              role="tab" 
              :aria-selected="tabs.barriers"
              @click="selectTab('barriers')"> Barriers </a>
          </li>

          <!-- Cameras, Displays, Tablets,  -->
          <!-- <li class="divider"></li> -->

          <!-- Settings -->
          <!-- <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.settings}"
              role="tab" 
              :aria-selected="tabs.settings"
              @click="selectTab('settings')"> {{$t('navigation.top.settings')}} </a>
          </li>  -->
          <!-- Claim Settings, Barriers, Mailing List -->

          <!-- <li>
            <a 
              href="javascript:void(0);" 
              :class="{'active': tabs.invoices}"
              role="tab" 
              :aria-selected="tabs.invoices"
              @click="selectTab('invoices')"> {{ $t('sites.site.invoices')}} </a>
          </li> -->
        </ul>

        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
          <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; right: 0px;">
          <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
        </div>
      </div>

      <div class="tab-pane fade" v-show="tabs.information" :class="{'show active': tabs.information}" role="tabpanel">
        <information :site_id="id" :client_id="client_id"/>
      </div>
      <div v-if="$can('sites-whitelist-view')" class="tab-pane fade" v-show="tabs.whitelist" :class="{'show active': tabs.whitelist}" role="tabpanel">
        <whitelist v-if="site.id" :site="site" :client_id="client_id"/>
      </div>
      <div class="tab-pane fade" v-show="tabs.signin" :class="{'show active': tabs.signin}" role="tabpanel">
        <sign-ins :site="site" :client_id="client_id"/>
      </div>
      <!-- <div class="tab-pane fade" v-show="tabs.devices" :class="{'show active': tabs.devices}" role="tabpanel">
        <devices :site="site" :client_id="client_id" :siteCameras="siteCameras" :displays="displays" :siteTablets="siteTablets"/>
      </div> -->


      <div class="tab-pane fade" v-show="tabs.redDiesel" :class="{'show active': tabs.redDiesel}" role="tabpanel">
        <red-diesel v-if="!loading" :site="site" :client_id="client_id" :siteCameras="siteCameras" :displays="displays" :siteTablets="siteTablets"/>
      </div>
      <div class="tab-pane fade" v-show="tabs.refusal" :class="{'show active': tabs.refusal}" role="tabpanel">
        <refusal v-if="!loading" :site="site" :client_id="client_id" :siteCameras="siteCameras" :displays="displays" :siteTablets="siteTablets"/>
      </div>
      <div class="tab-pane fade" v-show="tabs.temperature" :class="{'show active': tabs.temperature}" role="tabpanel">
        <temperature v-if="!loading" :site="site" :client_id="client_id" :siteCameras="siteCameras" :displays="displays" :siteTablets="siteTablets"/>
      </div>
      <div class="tab-pane fade" v-show="tabs.property" :class="{'show active': tabs.property}" role="tabpanel">
        <LostProperty v-if="!loading" :site="site" :client_id="client_id" />
      </div>
      <div class="tab-pane fade" v-show="tabs.checklist" :class="{'show active': tabs.checklist}" role="tabpanel">
        <checklist v-if="!loading" :site="site" :client_id="client_id" />
      </div>
      <div class="tab-pane fade" v-if="$can('barriers-manage')" v-show="tabs.barriers" :class="{'show active': tabs.barriers}" role="tabpanel">
        <Barriers v-if="!loading" :site="site" :client_id="client_id" />
      </div>

      <!-- <div class="tab-pane fade" v-show="tabs.settings" :class="{'show active': tabs.settings}" role="tabpanel">
        <settings :site="site" :client_id="client_id"/>
      </div>
      <div class="tab-pane fade" v-show="tabs.invoices" :class="{'show active': tabs.invoices}" role="tabpanel">
        <invoices :site="site" :client_id="client_id"/>
      </div> -->
    </div>
  </div>
</template>

<script>
import Information from './information/Information.vue';
import SiteData from './data/Data.vue';
import Whitelist from './whitelist/Whitelist.vue';
import SignIns from './signin/SignIns.vue';
import Devices from './devices/Devices.vue';
import RedDiesel from './reddiesel/RedDiesel.vue';
import Refusal from './refusal/Refusal.vue';
import Temperature from './temperature/Temperature.vue';
import Settings from './settings/Settings.vue';
import Invoices from './invoices/Invoices.vue';
import SiteService from '../../../services/site.service';
import Checklist from './checklist/Checklist.vue';
import Barriers from './barriers/Barriers.vue';
import LostProperty from './lostproperty/LostProperty.vue';

export default {
  name: 'Site',
  components: {
    Information,
    SiteData,
    Whitelist,
    SignIns,
    Devices,
    RedDiesel,
    Refusal,
    Temperature,
    Settings,
    Invoices,
    Checklist,
    Barriers,
    LostProperty
},
  props: {
    id: Number
  },
  data() {
    return {
      tabs: {
        information: true, 
        data: false,                 
        whitelist: false,
        signin: false,
        devices: false,
        redDiesel: false,
        refusal: false,
        temperature: false,
        settings: false,  
        invoices: false,
        barriers: false,
        checklist: false,
        property: false
      },
      client_id: this.$store.state.auth.user.selectedClient,
      loading: true,
      site: [],
      siteCameras: [],
      displays: [],
      siteTablets: []
    }
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      e = e || window.event;
      if (e.ctrlKey) {
        switch (e.key) {
          case 'ArrowRight':
          case 'ArrowDown':
            this.nextTab();
            break;
          case 'ArrowLeft':
          case 'ArrowUp':
            this.previousTab();
            break;
        }
      }
    });
    this.loadSite();
  },
  methods: {
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    nextTab() {
      let tabNames = Object.keys(this.tabs);
      let currentTab = null;

      Object.keys(this.tabs).forEach((t) => {
        if (this.tabs[t] === true) {
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      if (index + 1 > tabNames.length - 1) {
        index = 0;
      } else {
        index++;
      }

      this.selectTab(tabNames[index]);

    },
    previousTab() {
      let tabNames = Object.keys(this.tabs);
      console.log(tabNames);
      let currentTab = null;

      Object.keys(this.tabs).forEach((t) => {
        if (this.tabs[t] === true) {
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      console.log("Original Index:", index);
      if (index - 1 < 0) {
        index = tabNames.length - 1;
      } else {
        index--;
      }

      this.selectTab(tabNames[index]);
    },
    loadSite() {
      this.loading = true;
      SiteService.getSite(this.$props.id)
      .then(response => {
        console.log(response);
        this.site = response.data.site;
        this.siteCameras = response.data.checklist.cameras;
        this.displays = response.data.checklist.displays;
        this.siteTablets = response.data.checklist.tablets;
        this.loading = false;
        // console.log('Site.vue site array => ', response.data)
      })
    }
  }
}
</script>

<style scoped>
  .app-content .content-menu {
    padding: 0;
  }

  .app-content .content-menu li a.active::after {
    border-radius: 5px;
    width: 5px;
  }

  .app-content .content-menu li a {
    margin: 10px 0;
    font-size: 15px;
  }

  .app-content .content-menu li a:hover, .app-content .content-menu li a.active {
    font-weight: 400;
  } 


</style>