<template>
    <!-- <h1 class="table-heading m-b-xs"> Site Name Invoices </h1> --> 
    <div class="row">
        <!-- See if col-10 looks better, otherwise change to col-9 -->
        <div class="col-10" style="margin-left:280px">
            <h1 class="table-heading"> {{site.name}} {{$t('sites.invoice.invoices')}} </h1>

            <!-- Invoice Table -->
            <table v-show="!loading" class="table table-borderless" id="invoices">
                <thead>
                    <tr>
                        <th scope="col"> {{$t('sites.invoice.reference').toUpperCase()}} </th>
                        <th scope="col"> {{$t('sites.invoice.first_line').toUpperCase()}} </th>
                        <!-- Reference ?  -->
                        <!-- <th scope="col"> {{$t('')}} </th> -->
                        <th scope="col"> {{$t('sites.invoice.due').toUpperCase()}} </th>
                        <th scope="col"> {{$t('sites.invoice.paid').toUpperCase()}} </th>
                        <th scope="col"> {{$t('sites.invoice.total').toUpperCase()}} </th>
                        <th scope="col"> {{$t('claims.processing.status').toUpperCase()}} </th>
                        <th scope="col"> {{$t('sites.invoice.created_at').toUpperCase()}} </th>
                    </tr>
                </thead>

                <!-- Table Content -->
                <tbody>
                    <!-- If no data is present -->
                    <tr v-if="invoices.length < 1">
                        <td colspan="7" class="no-data-container"> {{ $t('global.no_data') }} </td>
                    </tr>

                    <tr v-else v-for="elem in invoices" :key="'invoice-' + elem.id">
                        <td> {{(elem.lineitems[0] || {description: ""}).description}}</td>
                        <td> {{elem.invoice_number}} </td>
                        <td> {{elem.reference}} </td>
                        <td> £{{parseFloat(elem.amount_due).toFixed(2)}} </td>
                        <td> £{{parseFloat(elem.amount_paid).toFixed(2)}} </td>
                        <td> £{{parseFloat(elem.total.toFixed(2))}} </td>
                        <td :class="elem.status === 'paid' ? 'status-container-success' : 'status-container-warning'"> {{ elem.status }} </td>
                        <td> <timestamp :time="elem.created_at" format="dd/MM/yyyy HH:mm:ss"></timestamp> </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>

</template>

<script>
import SiteService from '../../../../services/site.service';
import Timestamp from '../../../../components/Timestamp.vue';

export default {
    name: 'Invoices',
    components: {Timestamp},
    props: ['site', 'client_id'],
    data() {
        return {
            invoices: [],
            loading: true
        }
    },
    mounted() {
        this.loadInvoices();
    },
    methods: {
        loadInvoices() {
            this.loading = true;
            SiteService.getInvoices(this.$route.params.id)
            .then(response => {
                this.invoices = response.data.invoices;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
            })
        }
    }

}
</script>

<style>
    .table-heading {
        margin-bottom: 30px;
    }
</style>