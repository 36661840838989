<template>
  <div class="row m-t-xs">
    <div class="col-9" style="margin-left: 280px">

      <!-- <div class="card widget widget-list">
        <div class="section-description">
          <h1>{{$t('sites.site_information')}}</h1>
        </div>
        <div class="card-body" style="overflow-x: hidden">
          <form v-on:submit.prevent="createSite" class="row g-3">
            <div class="col-12 form-group">
                <label for="inputClient" class="form-label">Client <span class="text-danger">*</span></label>
                <select id="inputClient" class="form-select">
                    <option v-for="client in clients" :key="'clientselector-' + client.id">{{client.name}}</option>
                </select>
            </div>
            <div class="col-md-6 form-group">
              <label for="inputSiteName" class="form-label">{{$t('sites.name')}}</label>
              <input type="text" v-model="site.name" class="form-control" id="inputSiteName" placeholder="Site Name">
            </div>
            <div class="col-md-6 form-group">
              <label for="inputSiteReference" class="form-label">{{$t('sites.reference')}}</label>
              <input type="number" v-model="site.reference" class="form-control" id="inputSiteReference" placeholder="Site Reference">
            </div>
            <div class="col-12">
                <label for="inputTier" class="form-label">Tier <span class="text-danger">*</span></label>
                <select id="inputTier" class="form-select">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-12 form-group">
              <label for="inputAddress" class="form-label">{{$t('sites.address_one')}}</label>
              <input type="text" v-model="site.address_one" class="form-control" id="inputAddress" placeholder="1234 Main St">
            </div>
            <div class="col-12 form-group">
              <label for="inputAddress2" class="form-label">{{$t('sites.address_two')}}</label>
              <input type="text" v-model="site.address_two" class="form-control" id="inputAddress2">
            </div>
            <div class="col-md-12 form-group">
              <label for="inputCity" class="form-label">{{$t('sites.town')}}</label>
              <input type="text" v-model="site.address_three" class="form-control" id="inputCity">
            </div>
            <div class="col-md-6 form-group">
              <label for="inputCounty" class="form-label">{{$t('sites.county')}}</label>
              <input type="text" v-model="site.address_four" class="form-control" id="inputCity">
            </div>
            <div class="col-md-6 form-group">
              <label for="inputPostcode" class="form-label">{{$t('sites.postcode')}}</label>
              <input type="text" v-model="site.postcode" class="form-control" id="inputPostcode">
            </div>
            <div class="col-12 form-group">
              <button type="submit" class="btn btn-primary">{{$t('global.save_changes')}}</button>
            </div>
          </form>
        </div>
      </div> -->

      <h4 class="m-b-xs section-heading"> {{ $t('sites.site_information') }} </h4>
      <div class="card widget widget-list m-b-xxl">
        <div v-show="false" class="card-header">
          <i class="material-icons cursor-pointer" style="float: right;" @click="submitting = !submitting"> edit </i>
        </div>

        <div v-show="!loading" class="card-body">

          <!-- loading -->
          <div v-show="loading" style="width: 100%; text-align: center;">
            <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
              <span class="visually-hidden">{{ $t('events.loading') }}...</span>
            </div>
          </div>

          <!-- Site Information -->
          <form @submit.prevent="editSite" class="row g-3">
            <!-- <div class="col-12 form-group">
                <label for="inputClient" class="form-label">Client <span class="text-danger">*</span></label>
                <select id="inputClient" class="form-select" :disabled="!submitting">
                    <option selected class="form-control"> {{site.id}} </option>
                    <option v-for="client in clients" :key="'clientselector-' + client.id">{{client.name}}</option>
                </select>
            </div> -->
            <div class="col-md-6 form-group">
              <label for="inputSiteName" class="form-label">{{$t('sites.name')}}</label>
              <input type="text" v-model="site.name" class="form-control" id="inputSiteName" placeholder="Site Name" :disabled="!submitting">
            </div>
            <div class="col-md-6 form-group">
              <label for="inputSiteReference" class="form-label">{{$t('sites.reference')}} <span class="text-danger">*</span> </label>
              <input type="string" v-model="site.reference" class="form-control" id="inputSiteReference" placeholder="Site Reference" disabled>
            </div>
            <div class="col-12">
                <label for="inputTier" class="form-label"> Tier <span class="text-danger">*</span></label>
                <select id="inputTier" class="form-select" :disabled="!submitting" v-model="site.tier">
                    <option value="Full VARS System"> {{$t('sites.site.full_vars_system')}} </option>
                    <option value="Table Only"> {{$t('sites.site.tablet_only')}} </option>
                    <option value="Portal Only"> {{$t('sites.site.portal_only')}} </option>
                    <option value="Parking Only"> {{$t('sites.site.parking_only')}} </option>
                </select>
            </div>
            <div class="col-12 form-group">
              <label for="inputAddress" class="form-label">{{$t('sites.address_one')}}</label>
              <input type="text" v-model="site.address_one" class="form-control" id="inputAddress" placeholder="Address line 1" :disabled="!submitting">
            </div>
            <div class="col-12 form-group">
              <label for="inputAddress2" class="form-label">{{$t('sites.address_two')}}</label>
              <input type="text" v-model="site.address_two" class="form-control" id="inputAddress2" placeholder="Address line 2" :disabled="!submitting">
            </div>
            <div class="col-md-6 form-group">
              <label for="inputCity" class="form-label">{{$t('sites.town')}}</label>
              <input type="text" v-model="site.address_three" class="form-control" id="inputCity" :disabled="!submitting">
            </div>
            <div class="col-md-6 form-group">
              <label for="inputTelephone" class="form-label"> {{$t('sites.telephone')}}</label>
              <input type="tel" v-model="site.telephone" class="form-control" id="inputTelephone" :disabled=!submitting>
            </div>
            <div class="col-md-6 form-group">
              <label for="inputCounty" class="form-label">{{$t('sites.county')}}</label>
              <input type="text" v-model="site.address_four" class="form-control" id="inputCity" :disabled="!submitting">
            </div>
            <div class="col-md-6 form-group">
              <label for="inputPostcode" class="form-label">{{$t('sites.postcode')}}</label>
              <input type="text" v-model="site.postcode" class="form-control" id="inputPostcode" :disabled="!submitting">
            </div>
            <!-- Submit -->
            <div class="col-12 form-group flex-center p-v-xs">
              <button type="submit" class="btn btn-primary" :class="!submitting ? 'display-none' : 'display-block'">{{$t('global.save_changes')}}</button>
            </div>
          </form>
        </div>
      </div>

      <!-- <div class="card widget widget-list">
          <div class="section-description">
            <h4> {{ $t('sites.site.site_overview') }} </h4>
          </div>
          <div class="card-body">

          </div>
      </div> -->
      
      <!-- <h4 class="m-b-md section-heading"> {{ $t('sites.site.site_overview')}} </h4> -->

      <!-- <div v-show="!loading" class="row" style="justify-content: space-around;">
        <div class="col-3">
          <div class="card">
            <div class="card-title">
              <h5> {{ $t('global.driveoff') }} </h5>
            </div>
            <div class="card-body overview">
              <p> {{ $t('sites.site.lifetime') }} </p>
              <span> {{overview.driveoff}} </span>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="card">
            <div class="card-title">
              <h5> {{ $t('global.nmop') }} </h5>
            </div>
            <div class="card-body overview">
              <p> {{ $t('sites.site.lifetime') }} </p>
              <span> {{overview.nmop}} </span>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="card">
            <div class="card-title">
              <h5> {{ $t('global.parking') }} </h5>
            </div>
            <div class="card-body overview">
              <p> {{ $t('sites.site.lifetime') }} </p>
              <span> {{overview.parking}} </span>
            </div>
          </div>
        </div>
      </div> -->

      <h4 class="m-b-md section-heading"> Fire Assembly Points </h4>
      <div class="card m-b-xxl">
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th> Assembly Point </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="point in assembly_points">
                <td> {{point}} </td>
                <td>
                  <button class="btn btn-danger" @click="removeAssemblyPoint(point)"> Delete </button>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" v-model="assembly_point" class="form-control" placeholder="Add Assembly Point">
                </td>
                <td>
                  <button class="btn btn-primary" @click="addAssemblyPoint"> Add </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <h4 class="m-b-xs section-heading"> {{ $t('sites.site.site_overview')}} </h4>
      <div class="card m-b-xxl">
        <div class="card-body">
          <div class="grid">
            <div class="flex-center"> 
              <div class="card-title" style="padding: 0; text-align: center;"> 
                <h5> {{ $t('global.driveoff') }} </h5>
                <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
              </div>
              <div class="bg-circle">
                <span> {{overview.driveoff}} </span>
            </div>
            </div>

            <div class="flex-center"> 
              <div class="card-title" style="padding: 0; text-align: center;"> 
                <h5> {{ $t('global.nmop') }} </h5>
                <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
              </div>
              <div class="bg-circle">
                <span> {{overview.nmop}} </span>
              </div>
            </div>
            
            <div class="flex-center">
              <div class="flex-center"> 
                <div class="card-title" style="padding: 0; text-align: center;"> 
                  <h5> {{ $t('global.parking') }} </h5>
                  <span> {{ $t('sites.site.lifetime').toUpperCase() }} </span>
                </div>
                <div class="bg-circle">
                  <span> {{overview.parking}} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 class="section-heading m-b-xs"> {{$t('global.site', {text: 'Checklist'})}} </h4>
      <!-- Site Details -->
      <div class="card">
        <div class="card-body">
          <h5 class="m-b-md"> 
            <i v-if="site_details < 100" class="material-icons m-r-sm text-danger" style="vertical-align: middle;"> cancel </i> 
            <i v-if="site_details > 99" class="material-icons m-r-sm text-success" style="vertical-align: middle;"> check_circle </i>
            {{$t('global.site', {text: 'Details'})}} 
            <span style="float: right;"> {{site_details}}/100 </span> 
          </h5>
          <ul v-if="checklistErrors.site_details !== ''" class="list-group list-group-flush" id="site-info">
            <li class="list-group-item">
              <span class="flex-fill text-danger"> {{checklistErrors.site_details}} </span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Active Cameras -->
      <div class="card">
        <div class="card-body">
          <h5 class="m-b-md"> 
            <i v-if="checklist.cameras.length > 0 && active_cameras > 55 && active_cameras < 100" class="material-icons m-r-sm text-danger" style="vertical-align: middle;"> cancel </i> 
            <i v-if="checklist.cameras.length > 0 && active_cameras > 99" class="material-icons m-r-sm text-success" style="vertical-align: middle;"> check_circle </i>
            {{$t('sites.site.active_cameras')}}
            <span style="float: right;"> {{active_cameras}}/100 </span>
          </h5>
          <ul v-if="checklist.cameras.length > 0" class="list-group list-group-flush" id="site-info">
            <li v-for="cam in checklist.cameras" :key="cam.index" class="list-group-item">
                <i v-if="cam.status === 'Online'" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
                <span class="flex-fill"> {{cam.name}} </span>
                <strong class="float-end"> Last Event: {{cam.last_event}}</strong>
            </li>
          </ul>
        </div>
      </div>

      <!-- Active Displays -->
      <div class="card">
        <div class="card-body">
          <h5 class="m-b-md"> 
            <i v-if="checklist.displays.length < 1 || active_displays < 55" class="material-icons m-r-sm text-danger" style="vertical-align: middle;"> cancel </i>
            <i v-if="checklist.displays.length > 0 && active_displays > 55 && active_displays < 100" class="material-icons m-r-sm text-warning" style="vertical-align: middle;"> error </i>  
            <i v-if="checklist.displays.length > 0 && active_displays > 99" class="material-icons m-r-sm text-success" style="vertical-align: middle;"> check_circle </i>
            {{$t('sites.site.active_displays')}}
            <span style="float: right;"> {{checklist.displays.length}} </span>
          </h5>
          <ul v-if="checklist.displays.length > 0" class="list-group list-group-flush" id="site-info">
            <li v-for="display in checklist.displays" :key="display.index" class="list-group-item">
              <span class="flex-fill"> {{display.name}} </span>
              <strong class="float-end"> Last Ping: {{display.last_ping}}</strong>
            </li>
          </ul>
        </div>
      </div>

      <!-- Active Tablets -->
      <div class="card">
        <div class="card-body">
          <h5 class="m-b-md"> 
            <i v-if="checklist.tablets.length < 1" class="material-icons m-r-sm text-danger" style="vertical-align: middle;"> cancel </i> 
            <i v-if="checklist.tablets.length > 0" class="material-icons m-r-sm text-success" style="vertical-align: middle;"> check_circle </i>
            {{$t('sites.site.active_tablets')}}
            <span style="float: right;"> {{checklist.tablets.length}} </span>
          </h5>
          <ul v-if="checklist.tablets.length > 0" class="list-group list-group-flush" id="site-info">
            <li v-for="tab in checklist.tablets" :key="tab.index" class="list-group-item">
              <span class="flex-fill"> {{tab.name}} </span>
              <strong class="float-end"> {{$t('sites.devices.last_authenticated')}}: {{tab.last_authenticated}}</strong>
            </li>
          </ul>
        </div>
      </div>

    </div>
    </div>
</template>

<script>
import ClientService from '../../../../services/client.service';
import SiteService from "../../../../services/site.service";
import _cloneDeep from 'lodash/cloneDeep';
import Timestamp from '../../../../components/Timestamp.vue';
import {DateTime} from 'luxon';
import axios from 'axios';
import authHeader from '../../../../services/auth-header';

export default {
  name: 'Information',
  props: ['site_id', 'client_id'],
  components: {Timestamp},
  data(){
    return{
      // submitting: false,
      loading: true,
      overviewLoaded: true,
      // site: {
      //   name: "",
      //   postcode: "",
      //   address_one: "",
      //   address_two: "",
      //   address_three: "",
      //   address_four: "",
      //   telephone: "",
      //   reference: "",
      //   client_id: parseInt(this.$store.state.auth.user.selectedClient)
      // }
      site: [],
      sites: [],
      checklist: {
        cameras: [],
        displays: [],
        events: -1,
        tablets: []
      },
      overview: {
        driveoff: 0,
        nmop: 0,
        parking: 0
      },
      submitting: false,
      originalSite: null,
      checklistErrors: {
        site_details: ''
      },
      assembly_points: [],
      assembly_point: ''
    }
  },
  mounted () {
    this.loadSite();
    // this.loadSites();
  },
  methods: {
    // createSite() {
    //   this.submitting = true;
    //   this.submitStatus = "Creating Site";
    //   SiteService.createSte(this.site).then((response) => {
    //     this.$router.push(`/sites/${response.data.site}`)
    //   });
    // },
    loadSite() {
      this.loading = true;
      SiteService.getSite(this.$props.site_id)
      .then(response => {
        response.data.checklist.cameras.forEach((camera) => {
          if(camera.last_event !== null && camera.last_event){
            camera.last_event = DateTime.fromSeconds(camera.last_event).toFormat('dd/MM/yyyy HH:mm:ss');
          }
        });
        response.data.checklist.displays.forEach((display) => {
          if(display.last_ping){
            display.last_ping = DateTime.fromSeconds(display.last_ping).toFormat('dd/MM/yyyy HH:mm:ss');
          }
        });
        response.data.checklist.tablets.forEach((tablet) => {
          if(tablet.last_authenticated){
            tablet.last_authenticated = DateTime.fromSeconds(tablet.last_authenticated).toFormat('dd/MM/yyyy HH:mm:ss');
          }
        });
        this.site = response.data.site;
        this.checklist = response.data.checklist;
        this.overview = response.data.overview;
        this.originalSite = _cloneDeep(this.site);
        this.loading = false;
        this.overviewLoaded = false;
        this.loadAssemblyPoint();
      })
    },
    loadAssemblyPoint(){
      axios.get(`https://api.varsanpr.com/api/signin/site/assemblypoint?site_id=${this.site.id}`, { headers: authHeader() })
      .then(response => {
        this.assembly_points = response.data.assembly_points;
      })
      .catch(error => {
        console.log(error);
      })
    },
    addAssemblyPoint(){
      axios.put(`https://api.varsanpr.com/api/signin/assemblypoint`, {
        assembly_point: this.assembly_point,
        site_id: this.site.id
      }, { headers: authHeader() })
      .then(response => {
        this.assembly_points.push(this.assembly_point);
        this.assembly_point = '';
      })
      .catch(error => {
        console.log(error);
        this.$error('Error adding assembly point', error);
      });
    },
    removeAssemblyPoint(ap){
      axios.delete(`https://api.varsanpr.com/api/signin/assemblypoint`, { headers: authHeader(), params: {
        assembly_point: ap,
        site_id: this.site.id
      } })
      .then(response => {
        this.assembly_points = this.assembly_points.filter((point) => {
          return point !== ap;
        });
      })
      .catch(error => {
        console.log(error);
        this.$error('Error removing assembly point', error);
      });
    },
    editSite() {
      this.loading = true;
      //Remove later when the api is fixed 
      this.site.max_stay = parseFloat(this.site.max_stay);
      this.site.parking_perc_fines = parseFloat(this.site.parking_perc_fines);
      this.site.parking_perc_rates = parseFloat(this.site.parking_perc_rates);
      this.site.no_return = parseFloat(this.site.no_return);

      SiteService.editSite(this.site.id, this.site)
      .then(response => {
        this.originalSite = _cloneDeep(this.site);
        this.loading = false;
        this.submitting = false;
        this.$success(this.$t('sites.site_updated'));
      })
      .catch(error => {
        this.$error(this.$t('sites.site_update_error', error));
        this.loading = false;
        this.submitting = false;
      })
    },
    loadSites() {
      ClientService.getClientSites(this.$store.state.auth.user.selectedClient, true).then(response => {
        // console.log(response);
        this.sites = response.data.sites.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.loading = false;
      })
    }
  },
  computed: {
      // clients: function(){
      //     return this.$store.state.auth.user.clients.sort((a,b) => {
      //       return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      //     });
      // },
      // currentClient: function(){
      //     return this.$store.state.auth.user.clients.filter((c) => {
      //         return parseInt(c.id) === parseInt(this.$store.state.auth.user.selectedClient);
      //     })[0];
      // }
      site_details: function() {
        let percentage = 0.00;
        let passed = 0;

        if(this.site.name !== null && this.site.name !== undefined) {
          passed++;
        } 
        if(this.site.address_one !== null && this.site.address_one !== undefined) {
          passed++;
        } 
        if(this.site.address_two !== null && this.site.address_two !== undefined) {
          passed++;
        }
        if(this.site.postcode !== null && this.site.postcode !== undefined) {
          passed++;
        } 

        percentage = (passed / 4) * 100;
        return percentage;
      },
      active_cameras: function() {
        let count = this.checklist.cameras.length;
        let active = 0;
        for(let cam of this.checklist.cameras) {
          if(cam.status === 'Online') {
            active++;
          }
        }
        if(active == 0 && count == 0) {
          return 0;
        }
        return (active/count) * 100;
      },
      active_displays: function() {
        let count = this.checklist.displays.length;
        let active = 0;
        for(let display of this.checklist.displays) {
          if(display.status === 'Online') {
            active++;
          }
        }
        return (active/count) * 100;
      }
  },
  watch: {
    checklist: function() {
      if(this.checklist.displays.length < 1) return;

      for(let display of this.checklist.displays) {
        // console.log(display.last_ping);
        // display.last_ping = DateTime.fromSeconds(display.last_ping).toFormat('dd/MM/yyyy HH:mm:ss');
        // console.log(DateTime.fromSeconds(display.last_ping).toFormat('dd/MM/yyyy HH:mm:ss'));
      }
    }
  }
}
</script>

<style scoped>

.grid {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
}

.flex-center {
  flex-direction: column;
}

.section-description {
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 0;
}

select:disabled {
  cursor: not-allowed;
}

input:disabled {
  cursor: not-allowed;
}

.card-title h5 {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.card-body.overview {
  padding: 10px 10px 20px;
  text-align: center;
}

table {
  margin: 0;
}

tr {
  border-bottom: none;
}

.active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4BAD48;
  opacity: .7;
  display: inline-block;
}

</style>