<template>
    <div class="row">
        <div class="col-9" style="margin-left: 280px">

            <table v-show="!barrier" class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="barriers.length < 1 && !loading">
                        <td colspan="3">No barriers...</td>
                    </tr>
                    <tr v-if="loading">
                        <td colspan="3">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="barriers.length > 0 && !loading" v-for="b in barriers" :key="b.id">
                        <td>{{ b.id }}</td>
                        <td>{{ b.name }}</td>
                        <td>
                            <button class="btn btn-sm btn-primary" @click="barrier = b">Manage</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <barrier v-if="barrier" :barrier_id="barrier.id" :site="site" @close="barrier = null"></barrier>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../../../services/auth-header';
import Barrier from './Barrier';

export default {
    name: 'Barriers',
    components: {
        Barrier
    },
    props: {
        site: Object
    },
    data(){
        return {
            barriers: [],
            loading: true,
            barrier: null
        }
    },
    mounted(){
        this.loadBarriers();
    },
    methods: {
        loadBarriers(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/sites/${this.site.id}/barriers`, {
                headers: authHeader()
            })
            .then(response => {
                this.barriers = response.data.barriers;
            })
            .catch(error => {
                this.$error(error.response.data.message, error);
            })
            .finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>