<template>
    <div class="row">
        <div class="col-12">

            <div class="m-t-sm" style="justify-content: space-between; display: flex;">
                <button class="btn btn-primary" @click="goBack"><i style="margin-left: 0px; margin-right: 0px;" class="material-icons">west</i></button>
                <h3>{{ date }}</h3>
                <button class="btn btn-primary" @click="goForward"><i style="margin-left: 0px; margin-right: 0px;" class="material-icons">east</i></button>
            </div>

            <div class="m-t-sm">
                <button class="btn btn-primary" @click="toggleInsert">Insert Question</button> 
                <button class="btn text-white float-right tx-uppercase tx-bold" @click="generateReport()" style="background-color: #1D6F42;">Export Excel</button>
            </div>

            <div class="card m-t-sm">
                <div class="card-body" v-show="showQuery">
                    <table class="table">
                        <thead>
                            <th>{{ "Question" }}</th>
                            <th>{{ "Section" }}</th>
                        </thead>
                        <tbody>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter Question" v-model="newQuestion">
                            </td>
                            <td>
                                <select class="form-control" v-model="newSection">
                                    <option value="" disabled selected>Enter Selection</option>
                                    <option v-for="section in sections" :value="section.value">{{ section.text }}</option>
                                </select>
                            </td>
                        </tbody>
                        <div class="message-box" v-show="showAddMessage">
                            Question Added
                        </div>
                        <button class="btn btn-primary m-t-sm" @click="putQuestions(newQuestion, newSection)" :disabled="newQuestion === '' || newSection === ''">Add</button>
                    </table>
                </div>
            </div>

            <div v-for="group in groups" class="card m-t-sm">
                <div class="card-header">
                    <h5 class="card-title">{{ group.name }}</h5>
                </div>
                <div class="card-body">
                    
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Answer</th>
                                <th>Edit Question</th>
                                <th>Remove Question</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="loading">
                                <td colspan="2">
                                    <div class="text-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!loading" v-for="question of group.questions" :key="'question-' + question.id">
                                <td>{{question.question}}</td>
                                <td>
                                    <i v-if="parseInt(question.answer) === 1" class="material-icons text-success">done</i>
                                    <i v-else-if="parseInt(question.answer) === 0" class="material-icons text-danger">close</i>
                                    <span v-else>{{question.answer}}</span>
                                </td>
                                <td>
                                    <button class="btn btn-primary" @click="selectedQuestion = question.id">Edit</button>
                                    <div v-if="selectedQuestion === question.id">
                                        <input type="text" class="form-control m-t-sm" v-model="editedQuestion">
                                        <select class="form-control m-t-sm" v-model="editedSection">
                                            <option value="" disabled selected>Enter Selection</option>
                                            <option v-for="section in sections" :value="section.value">{{ section.text }}</option>
                                        </select>
                                        <button class="btn btn-primary m-t-sm" @click="modifyQuestions(question.id)">Save</button>
                                        <button class="btn btn-danger m-t-sm" @click="selectedQuestion = null">Cancel</button>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger" @click="removeQuestion(question)">Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../../../services/auth-header';
import { DateTime } from 'luxon';
import { set } from 'lodash';
import { textHeights } from 'ol/render/canvas';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';

export default {
    name: 'ManagerChecklist',
    props: {
        site: Object
    },
    data(){
        return {
            showQuery: false,
            showAddMessage: false,
            showEdit: false,
            sectionListVisible: false,
            selectedQuestion: null,
            editedQuestion: '',
            editedSection: '',
            newQuestion: '',
            newSection: '',
            sections: [
                {value: 'due-diligence', text: 'Due Diligence'},
                {value: 'hot-food-area', text: 'Hot Food Area'},
                {value: 'customer-service', text: 'Customer Service'},
                {value: 'administration', text: 'Administration'},
                {value: 'security', text: 'Security'},
                {value: 'forecourt-area', text: 'Forecourt Area'}
            ],
            questions: [],
            answers: [],
            groups: [],
            date: DateTime.local().toFormat('yyyy-MM-dd'),
            loading: true
        }
    },
    mounted(){
        this.loadQuestions();
        this.loadAnswers();
    },
    methods: {
        loadQuestions(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/checklist?site_id=${this.site.id}&type=manager`, {
                headers: authHeader()
            })
            .then(response => {
                this.questions = response.data.questions;
                
                let groups = [];

                this.questions.forEach(question => {
                    question.answer = null;
                    if(!groups.includes(question.section)){
                        groups.push(question.section);
                    }
                });

                groups.forEach((group) => {
                    this.groups.push({
                        name: group.replace(/-/g, ' ').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
                        questions: this.questions.filter((question) => question.section === group)
                    })
                });

                this.loadAnswers();
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        loadAnswers(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/checklist/answers?site_id=${this.site.id}&date=${this.date}&type=manager`, {
                headers: authHeader()
            })
            .then(response => {
                let answers = response.data.answers;

                for (let question of this.questions){
                    question.answer = null;
                }

                for(let answer of answers) {
                    let question = this.questions.filter((question) => parseInt(question.id) == parseInt(answer.id))[0];
                    question.answer = answer.answer;
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        putQuestions(newQuestion, newSection){
            if (newQuestion === '') {
                this.$error("Question field is empty");
                return;
            }

            if (newSection === '') {
                this.$error("New section not selected");
                return;
            }

            this.loading = true;
            axios.put(`https://api.varsanpr.com/api/checklist/question`, 
            {    
                site_id: parseInt(this.site.id),
                question: newQuestion,
                section: newSection,
                type: 'manager'
                
            },
            {
                headers: authHeader()
            })
            .then(response => {
                console.log("New Question", response.data)

                this.showAddMessage = true;
                setTimeout(() => {
                    this.showAddMessage = false;
                }, 30000);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.groups = [];
                this.showQuery = !this.showQuery;
                this.loadQuestions();
                this.loadAnswers();
                this.loading = false;
            });

        },
        modifyQuestions(questionID) {
            this.loading = true;
            console.log("Data Check", this.editedQuestion, this.editedSection);
            
            axios.put(`https://api.varsanpr.com/api/checklist/question/update`, 
            {    
                site_id: parseInt(this.site.id),
                question_id: parseInt(questionID),
                question: this.editedQuestion,
                section: this.editedSection,
                type: 'manager'
                
            },
            {
                headers: authHeader()
            })
            .then(response => {
                console.log("Edited Question", response.data)

                this.showEdit = false;
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.groups = [];
                this.loadQuestions();
                this.loadAnswers();
                this.loading = false;
            });

            this.selectedQuestion = null;
            this.editedQuestion = null;
            this.editedSection = null;
        },
        removeQuestion(question) {
            if (confirm("Are you sure you want to delete this question?")){
                console.log(question.id);

                this.loading = true;

                axios.delete(`https://api.varsanpr.com/api/checklist`, 
                {
                    data: {
                        site_id: parseInt(this.site.id),
                        question_id: parseInt(question.id)
                    },
                    headers: authHeader()
                })
                .then(response => {
                    console.log("Question Removed", response.data)
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.groups = [];
                    this.loadQuestions();
                    this.loadAnswers();
                    this.newQuestion = null;
                    this.newSection = null;
                    this.loading = false;
                });
            }
        },
        toggleInsert() {
            this.showQuery = !this.showQuery;
        },
        toggleDropdown() {
            this.sectionListVisible = !this.sectionListVisible;
        },
        goBack(){
            this.date = DateTime.fromFormat(this.date, 'yyyy-MM-dd').minus({days: 1}).toFormat('yyyy-MM-dd');
            this.loadAnswers()
        },
        goForward(){
            this.date = DateTime.fromFormat(this.date, 'yyyy-MM-dd').plus({days: 1}).toFormat('yyyy-MM-dd');
            this.loadAnswers();
        },
        generateReport() {
            console.log("Exporting Started");

            let groupData = [];
            let ans;

            for (let group of this.groups) {
                for (let question of group.questions) {
                    if (question.answer ==1) {
                        ans = "✔";
                    } else if (question.answer == "0") {
                        ans = "❌";
                    } else {
                        ans = question.answer;
                    }
                    groupData.push({
                        "Section": group.name,
                        "Question": question.question,
                        "Answer": ans
                    });
                }
            }

            let book = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(groupData);
            XLSX.utils.book_append_sheet(book, ws, 'Manager Checklist Report');
            var wopts = { bookType:'xlsx', type: 'binary'};
            var wbout = XLSX.write(book, wopts);
            const s2ab = (s) => {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf)
                for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }
            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `ManagerChecklist.xlsx`);
        }
    }
}
</script>
