<template>
    
    <!-- Claim settings -->
    <div class="card">
        <div class="card-header">
            <h5 class="card-title"> {{$t('sites.settings.claim_settings')}}</h5>
            <hr>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label for="" class="form-label" style="margin-bottom: 10px"> {{$t('sites.settings.dashboard_pin')}}</label>
                <div class="flex-row">
                    <input type="text" name="" id="" class="form-control m-r-md" v-model="site.pin" placeholder="Generate Pin">
                    <button class="btn btn-secondary" @click="generatePin"> Generate Pin </button>
                </div>
            </div>
            
            <div class="form-group">
                <label class="form-label"> {{$t('navigation.side.claims')}}: </label>
                <div class="form-switch p-v-xs">
                    <input type="checkbox" class="form-check-input form-control-md"  @click="toggleSwitch('claims_enabled')" v-model="site.claims_enabled" :class="{'on': site.claims_enabled}">
                </div>
            </div>

            <div v-if="pinChanged || claim_enabled" class="flex-center">
                <button class="btn btn-primary" @click="saveChanges"> {{$t('global.save_changes')}} </button>
            </div>
        </div>
    </div>

    <!-- Tablet settings -->
    <div class="card">
        <div class="card-header">
            <h5 class="card-title"> {{$t('sites.settings.tab_settings')}} </h5>
            <hr>
        </div>
        <div class="card-body">
            <div class="grid-3-columns">
                <div class="form-group">
                    <label class="form-label"> {{$t('labels.tab_settings.lite_only')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('lite_only')" v-model="site.lite_only" :class="{'on': lite_only}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('global.driveoff')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('driveoff')" v-model="site.driveoff" :class="{'on': driveoff}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('global.nmop')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('nmop')" v-model="site.nmop" :class="{'checked': nmop}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('labels.tab_settings.staff_parking')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('staff_parking')" v-model="site.staff_parking" :class="{'checked': staff_parking}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('labels.tab_settings.vehicle_history')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('vehicle_history')" v-model="site.vehicle_history" :class="{'checked': vehicle_history}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('labels.tab_settings.customer_parking')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('customer_parking')" v-model="site.customer_parking" :class="{'checked': customer_parking}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('labels.tab_settings.sign_in')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('signin')" v-model="site.signin" :class="{'checked': signin}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('labels.tab_settings.evidence')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('evidence')" v-model="site.evidence" :class="{'checked': evidence}">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label"> {{$t('navigation.top.logout')}}:</label>
                    <div class="form-switch p-v-xs">
                        <input type="checkbox" class="form-check-input form-control-md" @click="toggleSwitch('logout')" v-model="site.logout" :class="{'checked': logout}">
                    </div>
                </div>


            </div>
            <div v-if="tabsChanged" class="flex-center">
                <button class="btn btn-primary"> {{$t('global.save_changes')}} </button>
            </div>
        </div>
    </div>

    <!-- Parking Settings -->
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{$t('labels.parking.parking_settings')}}</h5>
            <hr>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label class="form-label"> {{$t('global.parking')}}: </label>
                <div class="form-switch p-v-xs">
                    <input type="checkbox" class="form-check-input form-control-md"  @click="toggleSwitch('parking')" v-model="site.parking_enabled" :class="{'on': site.parking_enabled}">
                </div>
            </div>

            <div class="form-group">
                <label class="form-label"> {{$t('labels.parking.fine_perc')}}:</label>
                <!-- <vue-slider v-model="value"></vue-slider> -->
            </div>

             <div class="form-group">
                <label class="form-label"> {{$t('labels.parking.rates_perc')}}:</label>
            </div>

            <div class="form-group">
                <label class="form-label"> {{$t('labels.parking.max_stay')}}:</label>
            </div>

            <div class="form-group">
                <label class="form-label"> {{$t('labels.parking.no_return')}}:</label>
            </div>
        </div>
    </div>
    
    <div class="card">
        <div class="card-body">
            <div class="flex-center">
                <button class="btn btn-primary" @click="saveChanges"> {{$t('global.save_changes')}} </button>
            </div>
        </div>
    </div>
</template>

<script>
// import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/default.css';
import cryptoRandomString from 'crypto-random-string';
import siteService from '../../../../services/site.service';
// import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.js';

export default {
    name: 'SiteClaimSettings',
    props: {
        site: Object,
        client_id: Number | String
    },
    components: {
        // VueSlider
    },
    data() {
        return {
            originalSite: [],
            tabsChanged: false,
            value: 0
        }
    },
    mounted() {
        
    },
    methods: {
        toggleSwitch(value) {
            this.tabsChanged = false;
            this.site[value] = !this.site[value];
            this.tabsChanged = true;
        },
        generatePin() {
            this.site.pin = cryptoRandomString({length: 24, type: 'numeric'});
        },
        saveChanges() {
            //TODO - 500 ERROR 
            siteService.editSite(this.site.id, this.site)
            .then(() => {
                this.$success(this.$t('sites.site_updated'))
            })
            .catch(error => {
                this.$error(this.$t('sites.site_update_error'), error)
            })
        }
    },
    computed: {
        pinChanged: function() {
            return !(JSON.stringify(this.site.pin) === JSON.stringify(this.originalSite.pin))
        },
        claim_enabled: function() {
            return !(JSON.stringify(this.site.claims_enabled) === JSON.stringify(this.originalSite.claims_enabled))
        }
    },
    watch: {
        site: function() {
            this.originalSite = _.cloneDeep(this.site);
        }
    }
}
</script>

<style scoped>
    .form-control {
        width: 50%;
        display: inline;
    }

    .form-check-input {
       /* border-color: #4BAD48;  */
    }

    .form-check-input:checked {
        background-color: #2269F5;
        /* border-color: #4BAD48; */
    }

    .form-check-input:focus {
        /* border-color: #4BAD48; */
    }

    .form-check-input, .form-check-input:checked {
        width: 55px;
        height: 25px;
    }

    .toggle-switch {
        width: 50px;
        height: 25px;
        border-radius: 25px;
        background-color: #ccc;
        transform: .4s;
        position: relative;
    }

    .toggle-switch input {display: none;}

    .toggle-switch:before {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        top: 2.5px;
        left: 5px;
        background-color: white;
        transition: .4s;
        border-radius: 50px;
    }

    .form-group {
        margin-bottom: 20px;
    }

    .form-label {
        margin-bottom: 0;
        font-size: 15px;
        /* color: white; */
        font-weight: 100;
    }

    .grid-3-columns {
        display: grid;
        grid-template-columns: 33% 33% 33%; 
        gap: 1em;
    }

</style>