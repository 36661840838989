import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class TabletService {
    //GET
    getTablet(tablet_id) {
        return axios.get(`${API_URL}tablets/${tablet_id}`, {headers: authHeader()});
    }
}

export default new TabletService();