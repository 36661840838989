<template>
    <div class="p-v-sm">
        <button class="btn text-white float-right tx-uppercase tx-bold" @click="generateReport" style="background-color: #1D6F42;">Export Excel</button> 

        <button class="btn btn-secondary m-b-sm" @click="$emit('hideFoodTemperature')"> <i class="material-icons"> arrow_back_ios_new</i> Go back </button>

        <!-- Filter -->
        <div class="accordion" id="filterAccordion">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                        {{$t('events.filters.title')}} ({{$t('events.filters.click_to_open')}})
                    </button>
                </h2>
                <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                    <div class="accordion-body">
                        <Form @submit="(values) => getData(pagination.page, values)" :validation-schema="schema" ref="form">
                            <div class="row"> 
                                <div class=" form-group col-md-3 col-xs-6 col-xxs-12">
                                    <label for="startDateFilter" class="form-label"> {{ $t('events.filters.start_date') }} </label>
                                    <input type="date" name="startDate" class="form-control" id="startDateFilter" v-model="filters.start_date" placeholder="Select start date"/>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="endDateFilter" class="form-label">{{ $t('events.filters.end_date') }}</label>
                                    <input type="date" name="endDate" class="form-control" id="endDateFilter" v-model="filters.end_date" placeholder="Select end date"/>
                                </div>
                            </div>

                            <div class="row m-t-md">
                                <div class="col-12" style="width:auto;">
                                    <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;" @click.prevent="getData">
                                        <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                        <span>{{ $t('events.filters.search') }}</span>
                                    </button>
                                </div>

                                <div class="col-12" style="width:auto;">
                                    <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="clearFilter">
                                        <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                        <span>{{ $t('global.clear_filter') }}</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

        <Pagination
            v-show="!loading"
            :config="pagination"
            @search ="getData"
        />

        <h6 class="table-heading"> Product - {{product.name}}</h6>
        <table class="table table-striped m-b-lg">
            <thead>
                <tr>
                    <th scope="col"> {{$t('sites.site.temperature').toUpperCase()}} </th>
                    <th scope="col"> DATE/TIME RECORDED </th>
                    <th scope="col"> {{$t('sites.temperature.init_qty_thaw').toUpperCase()}} </th>
                    <th scope="col"> {{$t('sites.temperature.qty_wasted_thaw').toUpperCase()}} </th>
                    <th scope="col"> {{ $t('sites.temperature.initials').toUpperCase() }} </th>
                    <th scope="col"> {{ $t('sites.temperature.shelf_life').toUpperCase() }} </th>
                </tr>
            </thead>

            
            <td colspan="7" v-if="loading" class="parent-div">
                <div class="spinner-border center-div" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </td>

            <tbody v-else>
                <tr v-show="productDetails.length < 1">
                <td colspan="7" class="no-data-container"> No data to show </td>
                </tr>
            <tr v-for="product in productDetails" :key="product.id" style="vertical-align: middle;">
                <td> {{parseFloat(product.temperature).toFixed(2)}} </td>
                <td> {{product.datetime}} </td>
                <td> {{product.initial_quantity}} </td>
                <td> {{product.quantity_wasted}} </td>
                <td> {{ product.initials }} </td>
                <td> {{ product.shelf_life }} </td>
            </tr>
            </tbody>
        </table>
    </div>
    
  
</template>

<script>
import temperatureService from '../../../../../../../services/temperature.service';
import Pagination from '../../../../../../../components/Pagination.vue';
import { DateTime } from 'luxon';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';


export default {
    name: 'FoodDetails',
    props: {
       product: Object
    },
    components: {
        Pagination
    },
    data() {
        return {
            loading: true,
            productDetails: [],
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            },
            filters: {
                start_date: null,
                end_date: null
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData(page = 1) {
            this.loading = true;
            temperatureService.getHotFoodTemperature(this.$route.params.id, this.product.id, this.filters.start_date, this.filters.end_date, page)
            .then(response => {
                response.data.submissions.forEach((item) => {
                    item.datetime = DateTime.fromSeconds(item.datetime).toFormat('dd LLL yyyy HH:mm:ss');
                });
                this.productDetails = response.data.submissions;
                this.pagination = response.data.pagination;
            })
            .catch(error => {
                this.$error(this.$t('sites.temperature.food_temp_failed'), error);
            })
            .finally(() => {
                this.loading = false;
            })
        },
        clearfilter() {
            this.filters.start_date = null;
            this.filters.end_date = null;
        },
        async fetchAllFoodTemps() {
            const allData = [];

            for (let page = 1; page <= this.pagination.lastPage; page++) {
                try {
                const response = await temperatureService.getHotFoodTemperature(
                    this.$route.params.id,
                    this.product.id,
                    this.filters.start_date,
                    this.filters.end_date,
                    page
                );

                const pageData = response.data.submissions;
                allData.push(...pageData);
                } catch (error) {
                console.error("Error fetching data:", error);
                }
            }

            return allData;
        },
        async generateReport(){
            console.log("Exporting Excel");
            const allData = await this.fetchAllFoodTemps(); 
            
            if (allData.length === 0){
                window.alert("No Data to Export!")
            } else {
                const temperatureData = allData.map((entry) => ({
                        "Daily Temperature": parseFloat(entry.temperature).toFixed(2),
                        "Date/Time": DateTime.fromSeconds(entry.datetime).toFormat('dd LLL yyyy HH:mm:ss'),
                        "Put Out": entry.initial_quantity,
                        "Waste": entry.quantity_wasted,
                        "Staff Initials": entry.initials,
                        "Shelf Life (Days)": entry.shelf_life
                    }));

                const book = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(temperatureData);
                XLSX.utils.book_append_sheet(book, ws, this.product.name + ' Report');
                const wopts = { bookType:'xlsx', bookSST:false, type: 'array'};
                const wbout = XLSX.write(book, wopts);
                saveAs(new Blob([wbout], {type:"application/octet-stream"}), this.product.name + ' Report.xlsx');
            }
        }
    }
}
</script>

<style>

</style>