<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">

      <div class="accordion" id="filterAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters"
                    aria-expanded="false" aria-controls="filters">
              Filters (Click to Open)
            </button>
          </h2>
          <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne"
               data-bs-parent="#filterAccordion">
            <div class="accordion-body">
              <Form @submit="(values) => loadWhitelist(pagination.page, values)" :validation-schema="schema" ref="form">
                <div class="row">
                  <div class="col-xl-3 col-xs-6 col-xxs-12">
                    <label for="vehicleRegistrationFilter" class="form-label">Vehicle Registration</label>
                    <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" placeholder="Enter Registration"/>
                    <ErrorMessage name="registration" class="error-feedback"/>
                  </div>
                </div>
                <div class="row m-t-md">
                  <div class="col-12" style="width:auto;">
                    <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                      <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                      <span> {{$t('claims.processing.search')}} </span>
                    </button>
                  </div>

                  <div class="col-12" style="width:auto;">
                    <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="(values) => clearFilter(pagination.page, values)">
                        <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                        <span>{{ $t('global.clear_filter') }}</span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

    <!-- Table -->
    <h1 class="table-heading"> {{$t('sites.site_whitelist')}} </h1>

    <!-- loading -->
      <div v-show="loading" style="width: 100%; text-align: center;">
          <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
              <span class="visually-hidden">{{ $t('events.loading') }}...</span>
          </div>
      </div>

      <!-- Pagination -->
      <Pagination v-show="!loading" :config="pagination" @search="(page) => loadWhitelist(page, currentParams)"/>

      <!-- Site Whitelist -->
      <table v-show="!loading" class="table table-striped m-t-lg m-b-lg">
        <thead>
          <tr>
            <th scope="col">{{$t('whitelist.registration')}}</th>
            <th scope="col">{{$t('whitelist.notes')}}</th>
            <th scope="col">{{$t('whitelist.added_on')}}</th>
            <th scope="col">{{$t('whitelist.expiry')}}</th>
            <th scope="col"> {{$t('global.action')}} </th>
          </tr>
        </thead>
        <tbody>
          <!-- If no data is present -->
          <tr v-show="whitelist.length < 1">
              <td colspan="5" class="no-data-container"> {{ $t('global.no_data') }} </td>
          </tr>

          <!-- Table Content -->
          <tr class="new-evidence-btn" @click="openAddVehicleModal()">
            <td colspan="5">
              <i class="material-icons text-success p-h-xs" style="vertical-align: middle;">add_circle</i> 
              <span class="text-success"> Add New Vehicle </span>
            </td>
          </tr>
          <tr v-for="list in whitelist" :key="'whitelist-' + list.registration" style="vertical-align: middle;">
            <td>
              <span class="vrm bold number-plate-gbr">{{ list.registration }}</span>
            </td>
            <td> {{list.notes}}</td>
            <td>
              <timestamp :time="list.added" format="dd/MM/yyyy HH:mm:ss"></timestamp>
            </td>
            <td>{{ !!list.expiry ? list.expiry : 'No Expiry' }}</td>
            <td>
              <button class="btn btn-danger btn-style-light flex-grow-1 m-1-xxs"
                      @click="removeWhitelist(list.registration)"><i class="material-icons">close</i>Remove
              </button>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- Add Vehicle Modal -->
      <div class="modal fade" id="addVehicleModal" tabindex="-1" aria-labelledby="addVehicleModalTitle" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content p-h-xxl p-v-xxl">
              <div class="modal-title flex-center">
                  <h3 class="m-r-sm"> ADD VEHICLE TO WHITELIST </h3>
              </div>
              <div> 
                  <div class="row">
                    <div class="col-12">
                        <label for="vehicleRegistration" class="form-label">Vehicle Registration <span class="text-danger">*</span></label>
                        <input type="text" v-model="vehicle.registration" name="registration" class="form-control" id="vehicleRegistration" placeholder="Enter Registration"/>
                    </div>
                    <div class="col-12 m-t-sm">
                      <label for="vehicleNotes" class="form-label">Notes <span class="text-danger">*</span></label>
                      <textarea type="textarea" v-model="vehicle.notes" name="notes" class="form-control" id="vehicleNotes" placeholder="Enter Notes"/>
                    </div>
                    <div class="col-12 m-t-sm">
                      <label for="vehicleExpiry" class="form-label">Expiry</label>
                      <input type="date" v-model="vehicle.expiry" name="vehicleExpiry" class="form-control" id="vehicleExpiry"/>
                    </div>
                  </div>
              </div>
              <div class="flex-center m-t-sm">
                  <button :disabled="loading" class="btn btn-secondary m-r-md" @click="() => modal.hide()"> 
                    <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                    <span>{{$t('global.cancel')}} </span>
                  </button>
                  <button :disabled="loading" class="btn btn-primary" @click="addWhitelist()"> 
                    <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                    <span>Add Vehicle</span>
                  </button>
              </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <Pagination v-show="!loading" :config="pagination" @search="(page) => loadWhitelist(page, currentParams)"/>
    </div>
  </div>
</template>

<script>
import * as yup from 'yup';
import {Form, Field, ErrorMessage} from 'vee-validate';
import Timestamp from '../../../../components/Timestamp.vue';
import Pagination from '../../../../components/Pagination.vue';
import WhitelistService from '../../../../services/whitelist.service';
import {DateTime} from 'luxon';

export default {
  components: {Form, Timestamp, Field, ErrorMessage, Pagination},
  name: 'Whitelist',
  props: {
    site: Object,
    client_id: Number
  },
  data() {
    const schema = yup.object().shape({
      registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must be no more than 12 characters!'),
    });
    return {
      loading: true,
      whitelist: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0
      },
      vehicle: {
        registration: null,
        notes: null,
        expiry: null
      },
      schema: schema,
      currentParams: {},
      modal: null
    }
  },
  mounted() {
    this.modal = new bootstrap.Modal(document.getElementById('addVehicleModal'));
    this.loadWhitelist();
  },
  methods: {
    loadWhitelist(page = 1, params = {}) {
      this.loading = true;
      this.currentParams = params;
      WhitelistService.getSiteWhitelist(page, this.site.id, params).then((response) => {
        this.pagination = response.data.pagination;
        response.data.whitelist.forEach((entry) => {
          if(entry.expiry){
            entry.expiry = DateTime.fromSeconds(entry.expiry).toFormat('dd/MM/yyyy');
          }
        })
        this.whitelist = response.data.whitelist;
        this.loading = false;
      });
    },
    removeWhitelist(registration) {
      WhitelistService.removeSiteWhitelist(this.site.id, registration).then((response) => {
        this.whitelist = this.whitelist.filter((el) => {
          return el.registration !== registration;
        });
        this.$success("Removed vehicle from whitelist!");
      })
      .catch(error => {
        this.$error("Failed to remove vehicle from whitelist! " + error.response.data.message, error);
      })
    },
    clearFilter(page = 1, params = {}) {
        this.loadWhitelist(page, this.$store.state.auth.user.selectedClient, params);
        this.$refs.form.resetForm();
    },
    openAddVehicleModal(){
      this.modal.show();
    },
    addWhitelist(){
      this.loading = true;
      WhitelistService.putSiteWhitelist(this.site.id, [this.vehicle]).then((response) => {
        this.loadWhitelist();
        this.vehicle = {
          registration: null,
          notes: null,
          expiry: null
        };
        this.modal.hide();
        this.$success("Added vehicle to whitelist!");
      })
      .catch(error => {
        this.$error("Failed to add vehicle to whitelist! " + error.response.data.message, error);
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

.new-evidence-btn {
  width: fit-content;
  padding: 10px;
}

.new-evidence-btn td:hover {
  background-color: rgba(75, 173, 72, .25);
  border-radius: 10px;
  cursor: pointer;
}

.new-evidence-btn td {
  text-align: center;
}

</style>