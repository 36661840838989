<template>
    <div class="row">
        <div class="col-9" style="margin-left: 280px">
            <!-- Filter -->
            <div class="accordion" id="filterAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                            {{$t('events.filters.title')}} ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                        <div class="accordion-body">
                            <Form @submit="(values) => loadSignIns(pagination.page, values)" :validation-schema="schema" v-slot="{ meta }" ref="form">
                                <div class="row">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="vehicleRegistrationFilter" class="form-label">{{$t('events.filters.vehicle_registration')}}</label>
                                        <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" placeholder="Enter Registration"/>
                                        <ErrorMessage name="registration" class="error-feedback" />
                                    </div>  
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="companyFilter" class="form-label"> {{ $t('event.company') }} </label>
                                        <Field type="text" name="company" class="form-control" id="companyFilter" placeholder="Enter Company"/> 
                                        <ErrorMessage name="company" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="startDateFilter" class="form-label"> {{ $t('sites.sign_ins.date_range') }} </label>
                                        <div class="input-group">
                                            <Field type="date" name="startDate" class="form-control" id="startDateFilter" /> 
                                            <span class="input-group-text">to</span>
                                            <Field type="date" name="endDate" class="form-control" id="endDateFilter" /> 
                                        </div>
                                        <ErrorMessage name="startDate" class="error-feedback"/>
                                        <ErrorMessage name="endDate" class="error-feedback"/>
                                    </div>
                                </div>

                                <div class="row m-t-md">
                                    <div class="col-12" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('events.filters.search') }}</span>
                                        </button>
                                    </div>

                                    <div class="col-12" style="width:auto;">
                                        <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="(values) => clearFilter(pagination.page, values)">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('global.clear_filter') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Table Heading -->
            <h1 class="table-heading m-b-xs"> 
                {{ $t('sites.sign_ins.signins') }} 
                <!-- Exports -->
                <button v-if="signIns.length > 0" class="btn text-white float-right tx-uppercase tx-bold" @click="generateReport" style="background-color: #1D6F42;">Export Excel</button>
            </h1>

            <!-- Pagination -->
            <Pagination v-show="!loading" :config="pagination" @search="(page) => loadSignIns(page, currentParams)"/>

            <!-- Loading -->
            <div v-show="loading" style="width: 100%; text-align: center;">
                <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </div>

            <!-- SignIns -->
            <table v-show="!loading" class="table table-stripped m-t-lg m-b-lg" id="site-signins">
                <thead>
                    <tr>
                        <th scope="col"> {{$t('sites.name').toUpperCase()}} </th>
                        <th scope="col"> {{$t('event.company').toUpperCase()}} </th>
                        <th scope="col"> {{$t('claims.new.vehicleRegistration').toUpperCase()}} </th>
                        <th scope="col"> {{$t('sites.sign_ins.date_time_signedin').toUpperCase()}} </th>
                        <th scope="col"> {{$t('sites.sign_ins.date_time_signout').toUpperCase()}}</th>
                        <th scope="col"> {{$t('global.reason').toUpperCase()}} </th>
                        <th scope="col"> {{$t('whitelist.action').toUpperCase()}} </th>
                    </tr>
                </thead>

                <tbody style="vertical-align: middle;">
                    <!-- If no data is present -->
                    <tr v-show="signIns.length < 1">
                        <td colspan="7" class="no-data-container"> {{ $t('global.no_data') }} </td>
                    </tr>

                    <tr v-for="signin in signIns" :key="signin.datetime" >
                        <td> {{ signin.name }} </td>
                        <td> {{ signin.company }} </td>
                        <td> <span class="vrm bold number-plate-gbr">{{ signin.plate }}</span> </td>
                        <td> <timestamp :time="signin.datetime" format="dd/MM/yyyy HH:mm:ss"> </timestamp> </td>
                        <td> {{ signin.signout_timestamp }} ({{signin.duration}})</td>
                        <td> {{ signin.reason }}</td>
                        <td>
                            <i class="material-icons btn-icon" @click="sendData(signin)"> visibility </i>
                            <button class="btn btn-secondary btn-text" @click="sendData(signin)"> 
                                <span> {{ $t('sites.sign_ins.view_signature')}} </span>
                            </button>
                        </td>
                    </tr> 
                </tbody>
            </table>

            <!-- Pagination -->
            <Pagination v-show="!loading" :config="pagination" @search="(page) => loadSignIns(page, currentParams)"/>
        </div>
    </div>

    <!-- Signature Modal -->
    <div class="modal fade" id="signatureModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-h-xxl p-v-xxl">
                <img v-show="!signature.loading" :src="signature.url" alt="signature" class="image-container">
                <div v-if="selectedData !== null" class="bg-info  m-b-md p-h-xs p-v-xxs" id="signin-name-container">
                    <span class="signin-name"> {{$t('sites.name')}}:  {{selectedData.name}} </span>
                </div>
                <div class="flex-center">
                    <button class="btn btn-secondary m-r-md" @click="() => signatureModal.hide()"> {{$t('global.close')}} </button>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
import * as yup from 'yup';
import {Form, Field, ErrorMessage } from 'vee-validate';
import SiteService from '../../../../services/site.service';
import Pagination from '../../../../components/Pagination.vue';
import Timestamp from '../../../../components/Timestamp.vue';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import axios from 'axios';
import authHeader from '../../../../services/auth-header';

export default {
    name: 'SignIns',
    components: {Form, Field, ErrorMessage, Pagination, Timestamp},
    props: ['site', 'client_id'],
    data() {
        const schema = yup.object().shape({
                registration: yup.string().nullable().min(3, 'Must be at least 3 characters!').max(12, 'Must be no longer than 12 characters!'),
                company: yup.string().nullable(),
                startDate: yup.date().when('endDate', {
                    is: (val) => val !== null && val !== '' && val !== undefined,
                    then: yup.date().max(yup.ref('endDate'), 'Start date must be before end date!').required('Start date is required when an end date has been supplied!'),
                    otherwise: yup.date().nullable(true)
                }),
                endDate: yup.date().when('startDate', {
                    is: (val) => val !== null && val !== '' && val !== undefined,
                    then: yup.date().min(yup.ref('startDate'), 'End date must be after start date!').required('End date is required when a start date has been supplied!'),
                    otherwise: yup.date().nullable(true)
                })
            }, [['startDate', 'endDate']])
        
        return {
            loading: false,
            schema,
            signIns: [],
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            } ,
            signatureModal: null,
            selectedData: null,
            signature: {
                url: null,
                loading: false
            }
        }
    },
    mounted() {
        this.loadSignIns();
        this.signatureModal = new bootstrap.Modal(document.getElementById('signatureModal'));
    },
    methods: {
        loadSignIns(page = 1, params = {}) {
            this.loading = true;
            this.currentParams = params;
            SiteService.getSignIns(page, this.$route.params.id, params)
                .then(response => {
                    this.loading = false;
                    this.pagination = response.data.pagination;
                    this.signIns = response.data.signin;
                    this.getSignoutTimestamp();
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                })
        },
        sendData(signin) {
            this.selectedData = signin;
            this.signature.loading = true;
            this.signatureModal.show();
            axios.get(`https://api.varsanpr.com/api/signin/${signin.sign}?site_id=${this.$route.params.id}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
            .then(response => {
                this.signature.url = `data:image/png;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
                this.signature.loading = false;
            })
            .catch(error => {
                this.$error(`Failed to download image ${this.filename}`, error);
                this.signature.loading = false;
                return null;
            });
        },
        clearFilter(page = 1, params = {}) {
            this.loadSignIns(page, this.$route.params.id, params);
            this.$refs.form.resetForm();
        },
        getSignoutTimestamp() {
            this.signIns.forEach(signin => {
                let start_time = signin.datetime; //in seconds
                switch(signin.duration) {
                    case '1 Hour': 
                       signin.signout_timestamp = start_time + 3600; 
                       signin.signout_timestamp = DateTime.fromSeconds(signin.signout_timestamp).toFormat('dd/MM/yyyy HH:mm:ss');
                       break;
                    case '1 Day':
                        signin.signout_timestamp = start_time + 86400;
                        signin.signout_timestamp = DateTime.fromSeconds(signin.signout_timestamp).toFormat('dd/MM/yyyy HH:mm:ss');
                        break;
                    case '1 Week':
                        signin.signout_timestamp = start_time + 604800;
                        signin.signout_timestamp = DateTime.fromSeconds(signin.signout_timestamp).toFormat('dd/MM/yyyy HH:mm:ss');
                        break;
                    case '2 Weeks': 
                        signin.signout_timestamp = start_time + (2 * 604800);
                        signin.signout_timestamp = DateTime.fromSeconds(signin.signout_timestamp).toFormat('dd/MM/yyyy HH:mm:ss');
                        break;
                    case '6 months':
                        signin.signout_timestamp = start_time + (6 * 2628000);
                        signin.signout_timestamp = DateTime.fromSeconds(signin.signout_timestamp).toFormat('dd/MM/yyyy HH:mm:ss');
                        break;
                    default:
                        try{
                            let duration = DateTime.fromFormat(signin.duration, 'yyyy-MM-dd HH:mm:ss.SSS', {
                                zone: 'utc'
                            });
                            if(duration.isValid) {
                                signin.signout_timestamp = duration.toLocal().toFormat('dd/MM/yyyy HH:mm:ss');
                                signin.duration = duration.diff(DateTime.fromSeconds(signin.datetime), ['days', 'hours', 'minutes', 'seconds']).toHuman();
                            }
                        } catch(e) {
                            console.log(e);
                        }
                        break;
                }
            }) 
        },
        generateReport(){
            console.log("Exporting Excel");
            let data = [];
            for(let entry of this.signIns){
                let inTime = "";
                if(entry.datetime){
                    inTime = DateTime.fromSeconds(entry.datetime).toFormat('dd/MM/yyyy HH:mm:ss');
                }
                let outTime = "";
                if(entry.signout_timestamp){
                    console.log(entry.signout_timestamp);
                    outTime = entry.signout_timestamp;
                }
                data.push({
                    "Name": entry.name,
                    "Company": entry.company,
                    "Vehicle Registration Plate": entry.plate,
                    "Signed In": inTime,
                    "Signed Out": outTime,
                    "Reason": entry.reason
                });
            }
            let book = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(book, ws, 'SignInReport');
            var wopts = { bookType:'xlsx', bookSST:false, type: 'array'};
            var wbout = XLSX.write(book,wopts);
            /* the saveAs call downloads a file on the local machine */
            saveAs(new Blob([wbout],{type:"application/octet-stream"}), `SignInReport.xlsx`);
        }
    }
}
</script>

<style>
    .image-container {
        width: 100%;
        height: auto;
        margin: auto;
        background-color: white;
        border-radius: 5px 5px 0 0;
    }

    .signin-name {
        color: white; 
        margin-bottom: 20px;
    }

    #signin-name-container {
        border-radius: 0 0 5px 5px;
    }

    .btn-text {
        display: none;
    }

    .btn-icon {
        cursor: pointer;
        padding: 5px;
    }

    /* Media queries */
    @media (min-width: 1510px) {
        .btn-icon {
            display: none;
        }

        .btn-text {
            display: flex;
        }
    }
</style>