<template>
  <div class="row space-between">
    <div class="col-12 m-b-md">
      
      <!-- Add users to mailing list  -->
      <h5 class="m-b-xs" style="font-weight: 100;"> {{$t('sites.mailing_list.add_to_list')}} </h5>
      <MessageCard 
        :alertType="'indicator-info'"
        :alertTitle="$t('claims.new.comments')"
        :alertText="$t('sites.mailing_message')"
      />

      <div class=" row form-group">
        <label for="" class="form-label"> {{$t('manage_evidence.labels.user')}} </label>
        <div class="col-8">
          <select v-show="!loading" v-model="selected_user" class="form-select m-b-sm" ref="userInput">
            <option value="null" disabled selected> Select User </option>
            <option v-for="user in users" :value="user.id" :key="user.id"> {{user.email}} </option>
          </select>
        </div>
        <div class="col-3">
          <button class="btn btn-primary" @click="addUser"> {{$t('global.add')}} </button>
        </div>
      </div>
    </div>  

    <!-- Display Mailing List -->
    <div class="col-12">
      <h5 class="section-heading m-b-xs" style="font-weight: 100;"> {{$t('sites.mailing_list.current_users')}} </h5>
      <table class="table" id="mailing-list">
        <thead>
          <tr>
            <th> {{$t('sites.name')}} </th>
            <th> {{$t('global.action')}} </th>
          </tr>
        </thead>

        <tbody>
          <tr v-show="existing_users.length < 1">
            <td colspan="2" class="no-data-container"> {{$t('global.no_data')}} </td>
          </tr>
          <tr v-for="user in existing_users" :key="user.id" style="vertical-align: middle;">
            <td>{{user.email}}</td>
            <td> <button class="btn bg-danger text-white" @click="removeUser(user.id)"> {{$t('global.remove')}}</button> </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import MessageCard from '../../../../components/MessageCard.vue';
import SiteService from '../../../../services/site.service';
import UserService from '../../../../services/user.service';

export default {
    name: 'MailingList',
    props: {
      site: Object,
      client_id: [Number, String]
    },
    components: { MessageCard },
    data() {
      return {
        users: [], //list of all users 
        loading: true,
        existing_users: [], // list of users in site mailing list
        loadingExisting: true,
        selected_user: null
      }
    },
    mounted() {
      this.getUsers();
      this.getSiteMailingList();
    },
    methods: {
      getUsers() {
        this.loading = true;
        UserService.getUsers()
        .then(response => {
          this.users = response.data.users;
          this.loading = false;
        })
        .catch(error => {
          this.$error('Failed to load users', error);
          this.loading = false;
        })
      },
      getSiteMailingList() {
        this.loadingExisting = true;
        SiteService.getSiteMailingList(this.$route.params.id)
        .then(response => {
          console.log(response);
          this.existing_users = response.data.list;
          this.loadingExisting = false;
        })
        .catch(error => {
          console.log(error);
          this.loadingExisting = false;
        })
      },
      addUser() {
        this.loading = true;
        SiteService.addToMailingList(this.$route.params.id, this.selected_user)
        .then(response => {
          this.$success(this.$t('sites.mailing_list.add_user_success'));
          this.existing_users.push({
            id: this.selected_user,
            email: this.users.filter(user => {return user.id === this.selected_user})[0].email
          })
          this.selected_user = "-1";
          this.loading = false;
        })
        .catch(error => {
          this.$error(this.$t('sites.mailing_list.add_user_error', error));
          this.loading = false;
        })
      },
      removeUser(user_id) {
        SiteService.removeFromMailingList(this.$route.params.id, user_id)
        .then((response) => {
          console.log(response);
          this.existing_users = this.existing_users.filter(user => {
            return user.id !== user_id;
          });
          this.$success(this.$t('sites.mailing_list.remove_user_success'));
        })
        .catch(error => {
          this.$error(this.$t('sites.mailing_list.remove_user_error'), error);
        })

      }
    }
}
</script>

<style scoped>
  .col-6 {
    width: 49%
  }

</style>