<template>
    <div class="row">
        <div class="col-12">

            <div class="m-t-sm" style="justify-content: space-between; display: flex;">
                <button class="btn btn-primary" @click="goBack"><i style="margin-left: 0px; margin-right: 0px;" class="material-icons">west</i></button>
                <h3>{{ date }}</h3>
                <button :disabled="blockGoForward" class="btn btn-primary" @click="goForward"><i style="margin-left: 0px; margin-right: 0px;" class="material-icons">east</i></button>
            </div>

            <div class="m-t-sm">
                <button class="btn btn-primary" @click="toggleInsert">Insert Question</button>                
                <button class="btn text-white float-right tx-uppercase tx-bold" @click="generateReport()" style="background-color: #1D6F42;">Export Excel</button> 
            </div>

            <div class="card m-t-sm">
                <div class="card-body" v-show="showQuery">
                    <table class="table">
                        <thead>
                            <th>{{ "Question" }}</th>
                        </thead>
                        <tbody>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter Question" v-model="newQuestion">
                            </td>
                        </tbody>
                        <div class="message-box" v-show="showAddMessage">
                            Question Added
                        </div>
                        <button class="btn btn-primary m-t-sm" @click="putQuestions(newQuestion)" :disabled="newQuestion === ''">Add</button>
                    </table>
                </div>
            </div>

            <table class="table">
                <thead>
                    <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Edit Question</th>
                        <th>Remove Question</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loading">
                        <td colspan="2">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="!loading" v-for="question of questions" :key="'question-' + question.id">
                        <td>{{question.question}}</td>
                        <td>
                            <i v-if="parseInt(question.answer) === 1" class="material-icons text-success">done</i>
                            <i v-else-if="parseInt(question.answer) === 0" class="material-icons text-danger">close</i>
                            <span v-else>{{question.answer}}</span>
                        </td>
                        <td>
                            <button class="btn btn-primary" @click="selectedQuestion = question.id">Edit</button>
                            <div v-if="selectedQuestion === question.id">
                                <input type="text" class="form-control m-t-sm" v-model="editedQuestion">
                                <button class="btn btn-primary m-t-sm" @click="modifyQuestions(question.id)">Save</button>
                                <button class="btn btn-danger m-t-sm" @click="selectedQuestion = null">Cancel</button>
                            </div>
                        </td>
                        <td>
                            <button class="btn btn-danger" @click="removeQuestion(question)">Remove</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { DateTime } from 'luxon';
import authHeader from '../../../../services/auth-header';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';

export default {
    name: 'ForecourtChecklist',
    props: {
        site: Object
    },
    data(){
        return {
            showQuery: false,
            showAddMessage: false,
            showEdit: false,
            selectedQuestion: null,
            editedQuestion: '',
            newQuestion: '',
            questions: [],
            answers: [],
            date: DateTime.local().toFormat('yyyy-MM-dd'),
            loading: true
        }
    },
    mounted(){
        this.loadQuestions();
    },
    methods: {
        loadQuestions(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/checklist?site_id=${this.site.id}&type=forecourt`, {
                headers: authHeader()
            })
            .then(response => {
                this.questions = response.data.questions;
                this.questions.forEach(question => {
                    question.answer = null;
                });
                this.loadAnswers();
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.loading = false;
            });;
        },
        loadAnswers(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/checklist/answers?site_id=${this.site.id}&date=${this.date}&type=forecourt`, {
                headers: authHeader()
            })
            .then(response => {
                let answers = response.data.answers;

                for (let question of this.questions){
                    question.answer = null;
                }

                for(let answer of answers) {
                    let question = this.questions.filter((question) => parseInt(question.id) == parseInt(answer.id))[0];
                    question.answer = answer.answer;
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        putQuestions(newQuestion){
            this.loading = true;

            if (newQuestion === '') {
                this.$error("Question not implemented");
                return;
            }

            axios.put(`https://api.varsanpr.com/api/checklist/question`, 
            {    
                site_id: parseInt(this.site.id),
                question: newQuestion,
                section: null,
                type: 'forecourt'
                
            },
            {
                headers: authHeader()
            })
            .then(response => {
                console.log("New Question", response);

                this.showAddMessage = true;
                setTimeout(() => {
                    this.showAddMessage = false;
                }, 30000);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.loadQuestions();
                this.loadAnswers();
                this.newQuestion = null;
                this.showQuery = !this.showQuery;
                this.loading = false;
            });

        },
        modifyQuestions(questionID) {
            console.log("Data Check", this.editedQuestion);
            
            axios.put(`https://api.varsanpr.com/api/checklist/question/update`, 
            {    
                site_id: parseInt(this.site.id),
                question_id: parseInt(questionID),
                question: this.editedQuestion,
                section: null,
                type: 'forecourt'
                
            },
            {
                headers: authHeader()
            })
            .then(response => {
                console.log("Edited Question", response.data)

                this.showEdit = false;
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                this.loadQuestions();
                this.loadAnswers();
            });

            this.selectedQuestion = null;
            this.editedQuestion = null;
            
        },
        removeQuestion(question) {
            if (confirm("Are you sure you want to delete this question?")){
                this.loading = true;

                axios.delete(`https://api.varsanpr.com/api/checklist`, 
                {
                    data: {
                        site_id: parseInt(this.site.id),
                        question_id: parseInt(question.id)
                    },
                    headers: authHeader()
                })
                .then(response => {
                    console.log("Question Removed", response.data)
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadQuestions();
                    this.loadAnswers();
                    this.loading = false;
                });
            }
        },
        toggleInsert() {
            this.showQuery = !this.showQuery;
        },
        goBack(){
            this.date = DateTime.fromFormat(this.date, 'yyyy-MM-dd').minus({days: 1}).toFormat('yyyy-MM-dd');
            this.loadAnswers()
        },
        goForward(){
            if(this.date == DateTime.local().toFormat('yyyy-MM-dd')){
                return;
            }
            this.date = DateTime.fromFormat(this.date, 'yyyy-MM-dd').plus({days: 1}).toFormat('yyyy-MM-dd');
            this.loadAnswers();
        },
        generateReport() {
            console.log("Exporting Excel");

            let QandA = [];
            let ans;
            for(let q of this.questions) {
                if (q.answer ==1) {
                    ans = "✔";
                } else if (q.answer == "0") {
                    ans = "❌";
                } else {
                    ans = q.answer;
                }
                QandA.push({
                    "Questions": q.question,
                    "Answers": ans
                });
            }

            let book = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(QandA);
            XLSX.utils.book_append_sheet(book, ws, 'Forecourt Checklist Report');
            var wopts = { bookType:'xlsx', bookSST:false, type: 'array'};
            var wbout = XLSX.write(book,wopts);
            /* the saveAs call downloads a file on the local machine */
            saveAs(new Blob([wbout],{type:"application/octet-stream"}), `ForecourtChecklist.xlsx`);
        }
    },
    computed: {
        blockGoForward: function(){
            return this.date == DateTime.local().toFormat('yyyy-MM-dd');
        }
    }
}
</script>