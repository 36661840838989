<template>
    <div class="row">
        <div style="padding: 20px 12px 0;">
            <span class="site-name"> {{name}} </span>
            <button class="btn btn-primary" style="float: right;" :class="{'display-none': tabs.inactive}" @click="() => addFridgeModal.show()"> {{$t('sites.temperature.add_calibration')}} </button>
        </div>

        <ul class="nav nav-tabs m-t-sm" role="tablist" style="padding: 0 12px">
            <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{'show active': tabs.active}" role="tab" :aria-selected="tabs.active" href="javascript:void(0);" @click="selectTab('active')"> Active ({{fridges.active.length}}) </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{'show active': tabs.inactive}" role="tab" :aria-selected="tabs.inactive" href="javascript:void(0);" @click="selectTab('inactive')">Inactive ({{fridges.inactive.length}}) </button>
            </li>
        </ul>

        <div class="tab-pane fade" v-show="tabs.active" :class="{'show active': tabs.active}" role="tabpanel">
            <active-devices :list="fridges.active" @fridge="deactivate" :name="name"></active-devices>
        </div>

        <div class="tab-pane fade" v-show="tabs.inactive" :class="{'show active': tabs.inactive}" role="tabpanel">
            <inactive-devices :list="fridges.inactive" @fridge="activate" :name="name"></inactive-devices>
        </div>

    </div>

    <div class="modal fade" id="addCalibrationDevice" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-h-xxl p-v-xxl">
                <div class="modal-title">
                    <h4 class="m-r-sm textAlign-center flex"> <span class="widget-list-item-icon"> <i class="material-icons-outlined" style="vertical-align: middle; padding: 10px;"> kitchen</i> </span>  {{$t('sites.temperature.add_calibration_device').toUpperCase()}} </h4>
                    <div class="form-group p-v-sm" style="width: 100%;">
                        <label class="form-label"> Device Name </label>
                        <input type="text" v-model="fridgeName" class="form-control" placeholder="Device Name">
                        <span class="text-danger" v-show="addFridgeError"> Device name already exits </span>
                    </div>
                    <div class="form-group p-v-sm" style="width: 100%;">
                        <label class="form-label"> Serial Number</label>
                        <input type="text" v-model="serialNumber" class="form-control" placeholder="Serial Number">
                    </div>
                    <div class="flex-center p-v-sm">
                        <button class="btn btn-secondary m-r-sm" @click="() => addFridgeModal.hide()"> {{$t('global.close')}} </button>
                        <button class="btn btn-primary" @click="addNewCalibrationDevice"> 
                            <span v-show="addingFridge" class="spinner-border spinner-border-sm m-r-xxs"></span>
                            <span> <i class="material-icons"> add </i> {{$t('global.add')}} </span> 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import temperatureService from '../../../../../services/temperature.service';
import {DateTime} from 'luxon';
import ActiveDevices from './devices/activedevices/ActiveDevices.vue';
import InactiveDevices from './devices/inactivedevices/InactiveDevices.vue';

export default {
    name: 'Calibration',
    props: {
        site_id: Number | String,
        name: String
    },
    components: {
        ActiveDevices,
        InactiveDevices
    },
    data() {
        return {
            loading: true,
            fridges: {
                active: [],
                inactive: []
            },
            tabs: {
                active: true,
                inactive: false
            },
            addFridgeModal: null,
            fridgeName: '',
            serialNumber: '',
            addingFridge: false,
            addFridgeError: false
        }
    },
    mounted() {
        this.getCalibrationDevices();
        this.addFridgeModal = new bootstrap.Modal(document.getElementById('addCalibrationDevice'));
    },
    methods: {
        getCalibrationDevices() {
            this.loading = true;
            temperatureService.getCalibrationDevices(this.site_id)
            .then(response => {
                response.data.data.forEach(fridge => {
                    fridge.datetime = DateTime.fromSeconds(fridge.datetime).toFormat('dd LLL yyyy HH:mm:ss');
                    if(fridge.status === 1) {
                        this.fridges.active.push(fridge);
                    } else {
                        this.fridges.inactive.push(fridge);
                    }
                });
                this.loading = false;
            })
            .catch(error => {
                this.$error(this.$t('sites.temperature.calibration_device_error'), error);
                this.loading = false;
            })
        },
        addNewCalibrationDevice() {
            this.addingFridge = true;
            this.addFridgeError = false;

            // Check if the device name already exists in the active or inactive fridges list
            if (this.fridges.active.some(fridge => fridge.name === this.fridgeName) || this.fridges.inactive.some(fridge => fridge.name === this.fridgeName)) {
                this.addFridgeError = true;
                this.addingFridge = false;
                return;
            }

            temperatureService.addNewCalibrationDevice(this.site_id, this.fridgeName, this.serialNumber)
            .then(response => {
                this.$success(this.$t('sites.temperature.add_calibration_device_success'));
                this.addingFridge = false;
                this.fridges.active.unshift({
                    name: response.data.fridge.name,
                    datetime: DateTime.fromFormat(response.data.fridge.datetime.replace('Z', '').replace('T', ' '), 'yyyy-MM-dd HH:mm:ss.SSS').toFormat('dd LLL yyyy HH:mm:ss'),
                    serial_number: response.data.fridge.serial_number 
                });
                this.addFridgeModal.hide();
            })
            .catch(error => {
                //TODO - check if the error is fridge name exits 
                console.log(error);
                this.addFridgeError = true;
                this.addingFridge = false;
            })
        },
        selectTab(tab) {
            for(let t in this.tabs) {
                this.tabs[t] = false;
            }
            this.tabs[tab] = true;
        },
        activate(fridge){
            console.log('activate', fridge);
            temperatureService.activateCalibrationDevice(this.site_id, fridge.id)
            .then(response => {
                this.$success('Activated Device successfully');
                this.fridges.active.unshift(fridge);
                this.fridges.inactive = this.fridges.inactive.filter(f => f.id !== fridge.id);
            })
            .catch(error => {
                this.$error('Failed to deactivate Device', error);
            })
        },
        deactivate(fridge){
            console.log('deactivate', fridge)
            temperatureService.deactivateCalibrationDevice(this.site_id, fridge.id)
            .then(response => {
                this.$success('Deactivated device successfully');
                this.fridges.inactive.unshift(fridge);
                this.fridges.active = this.fridges.active.filter(f => f.id !== fridge.id);
            })
            .catch(error => {
                this.$error('Failed to deactivate device', error);
            })
        }
    }
}
</script>

<style scoped>

    .nav-tabs {
        border-bottom: none;
    }

    .table-heading {
        margin-bottom: 0;
        margin-top: 40px;
        padding-top: 0px;
    }

    .widget-list-item-icon {
        width: 45px;
        height: 45px;
        border-radius: 10px;
        text-align: center;
        margin-right: 10px;
        vertical-align: middle;
        float: left;
        color: #fd7e14;
        background: rgba(255, 149, 0, .25)!important;
    } 

    .flex {
        align-items: center;
    }

    .display-none {
        display: none;
    }

    .site-name {
        font-size: 25px;
    }

    .m-r-sm.textAlign-center.flex {
        width: fit-content;
        margin: auto;
    }

    .text-danger {
        font-weight: 400;
    }

</style>