<template>
  <h4 class="section-heading m-b-md"> {{site.name}} Tablets ({{siteTablets.length}}) </h4>
  
  <!-- <ul class="nav nav-tabs mb-3" role="tablist">
    <li class="nav-item" role="presentation" v-for="tab in tabs" :key="'tab-' + tab.id">
      <button class="nav-link" data-bs-toggle="tab" type="button" role="tab" :aria-selected="tabs.list" :class="{'active': tab.showing}" href="javascript:void(0);" @click="selectTab(tab.name)"> {{tab.name}} </button>
    </li>
  </ul> -->

  <!-- <div class="form-group">
          <label for="tabletName" class="form-label"> {{$t('sites.name')}}</label>
          <div class="row no-m">
            <div style="padding-left: 0px;">
              <input type="text" id="tabletName" class="form-control" v-model="tablet.name">
            </div>
          </div>
        </div> -->

  <div v-if="!loading" class="row m-t-sm">
    <div v-for="tablet in tablets" :key="'tablet-' + tablet.id" class="card col-12" @click="selectTablet(tablet)">
      <div class="card-header">
        <span class="card-title"> {{tablet.name}} </span>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item cameras">
            <i v-if="tablet.client !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
            <strong class="flex-fill"> {{$t('sites.devices.assigned', {text: 'Client'})}} </strong>
            <span class="float-end"> {{tablet.client}}</span>
          </li>

          <li class="list-group-item cameras">
            <i v-if="tablet.site !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
            <strong class="flex-fill"> {{$t('sites.devices.assigned', {text: 'Site'})}} </strong>
            <span class="float-end"> {{tablet.site}}</span>
          </li>

          <li class="list-group-item cameras">
            <i v-if="tablet.last_authenticated !== null" class="material-icons text-success align-middle m-r-xs"> done </i> <i v-else class="material-icons text-danger align-middle m-r-sm"></i>
            <strong class="flex-fill"> {{$t('sites.devices.assigned', {text: 'Site'})}} </strong>
            <span class="float-end"> {{tablet.last_authenticated}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import TabletService from '../../../../../services/tablet.service';
import {DateTime} from 'luxon';
import Timestamp from '../../../../../components/Timestamp.vue';

export default {
    name: 'Tablets',
    props: {
      site: Object,
      client_id: Number | String,
      siteTablets: Array
    },
    components: {Timestamp},
    data() {
      return {
        tablets: [],
        loading: true,
        tabs: []
      }
    },
    mounted() {
      this.siteTablets.forEach(tablet => {
        this.tabs.push({
          name: tablet.name,
          id: tablet.id,
          tablet: true,
          showing: false
        })
      });
      if(this.tabs.length > 0) {
        this.tabs[0].showing = true;
      }
    },
    methods: {
      async getTablet() {
        this.loading = true;
        for await (let tablet of this.siteTablets) {
          TabletService.getTablet(tablet.id)
          .then(response => {
            if(response.data.tablet.last_authenticated) {
              response.data.tablet.last_authenticated = DateTime.fromSeconds(response.data.tablet.last_authenticated).toFormat("dd/MM/yyyy HH:mm:ss");
            }
            this.tablets.push(response.data.tablet);
          })
          .catch(error => {
            console.log(error);
          })
        }
        this.loading = false;
      },

    },
    watch: {
      siteTablets: function() {
        this.getTablet();
        this.siteTablets.forEach(tablet => {
          this.tabs.push({
            name: tablet.name,
            id: tablet.id,
            tablet: true,
            showing: false
          })
        });
        if(this.tabs.length > 0) {
          this.tabs[0].showing = true;
        }
      }
    }
}
</script>

<style scoped>

  .card:hover {
    box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
  }

  /* .col-5 {
    width: 47%;
  }

  @media(min-width: ) */

</style>