<template>
    <div class="row">
        <div class="col-9" style="margin-left: 280px">

            <!-- Tabs -->
            <div class="card widget widget-list">
                <ul class="nav nav-pills nav-justified">
                    <li class="nav-item">
                        <a 
                            class="nav-link" 
                            aria-current ="page" 
                            href="javascript:void(0);" 
                            :class="{'active' : tabs.documents}" 
                            role="tab"
                            :aria-selected="tabs.documents"
                            @click="selectTab('documents')"> {{$t('sites.data.documents')}} </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link"
                            aria-current="page" 
                            href="javascript:void(0)"
                            :class="{'active' : tabs.installPics}"
                            role="tab"
                            :aria-selected="tabs.documents"
                            @click="selectTab('installPics')"> {{$t('sites.data.installPics')}} </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link"
                            aria-current="page" 
                            href="javascript:void(0)"
                            :class="{'active' : tabs.signage}"
                            role="tab"
                            :aria-selected="tabs.signage"
                            @click="selectTab('signage')"> {{$t('sites.data.signage')}} </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link" 
                            aria-current="page"
                            href="javascript:void(0)"
                            :class="{'active' : tabs.brands}"
                            role="tab"
                            :aria-selected="tabs.brands"
                            @click="selectTab('brands')"> {{$t('sites.data.brands')}} </a>
                    </li>
                </ul>
            </div>

            <h4 class="section-heading p-v-xs p-h-md"> {{site.name}} </h4>

            <div class="tab-pane fade" v-show="tabs.documents" :class="{'show active': tabs.documents}" role="tabpanel">
                <Documents :site="site" :client_id="client_id"/>
            </div>
            <div class="tab-pane fade" v-show="tabs.installPics" :class="{'show active': tabs.installPics}" role="tabpanel">
                <InstallPics :site="site" :client_id="client_id"/>
            </div>
            <div class="tab-pane fade" v-show="tabs.signage" :class="{'show active': tabs.signage}" role="tabpanel">
                <Signage :site="site" :client_id="client_id"/>
            </div>
            <div class="tab-pane fade" v-show="tabs.brands" :class="{'show active': tabs.brands}" role="tabpanel">
                <Brands :site="site" :client_id="client_id"/>
            </div>

        </div>
    </div>
</template>

<script>
import Documents from './Documents.vue';
import InstallPics from './installPics.vue';
import Signage from './Signage.vue';
import Brands from './Brands.vue';
import SiteService from '../../../../services/site.service';

export default {
  name: 'SiteData',
  components: {Documents, InstallPics, Signage, Brands},
//   props: {
//       id: Number,
//   },
  props: ['site', 'client_id'],
  data() {
      return {
          tabs: {
            documents: true,
            installPics: false,
            signage: false,
            brands: false
          },
        //   client_id: this.$store.state.auth.user.selectedClient,
          loading: true,
          site: this.$props.site
      }
  },
  methods: {
      selectTab(tab) {
          for(let elem in this.tabs) {
              this.tabs[elem] = false;
          }
          this.tabs[tab] = true;
      }
  }
}
</script>

<style scoped>
    .section-heading {
        margin: 0;
    }
</style>