<template>
    <div  v-if="!productSelected" class="p-v-sm">

        <!-- Filter -->
        <!-- <div class="accordion" id="filterAccordion">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                        {{$t('events.filters.title')}} ({{$t('events.filters.click_to_open')}})
                    </button>
                </h2>
                <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                    <div class="accordion-body">
                        <form>
                            <div class="row"> 
                                <div class=" form-group col-md-3 col-xs-6 col-xxs-12">
                                    <label for="startDateFilter" class="form-label"> {{ $t('events.filters.start_date') }} </label>
                                    <input type="date" name="startDate" class="form-control" id="startDateFilter" v-model="filters.start_date" placeholder="Select start date"/>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="endDateFilter" class="form-label">{{ $t('events.filters.end_date') }}</label>
                                    <input type="date" name="endDate" class="form-control" id="endDateFilter" v-model="filters.end_date" placeholder="Select end date"/>
                                </div>
                            </div>

                            <div class="row m-t-md">
                                <div class="col-12" style="width:auto;">
                                    <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;" @click="getData">
                                        <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                        <span>{{ $t('events.filters.search') }}</span>
                                    </button>
                                </div>

                                <div class="col-12" style="width:auto;">
                                    <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="(values) => clearFilter(pagination.page, values)">
                                        <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                        <span>{{ $t('global.clear_filter') }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->

        <pagination v-show="!loading" :config="pagination" @search="getProductList"></pagination>

        <table class="table table-striped m-b-lg" id="daily-food-temp">
            <thead>
                <tr>
                    <th scope="col"> ID </th>
                    <th scope="col"> {{$t('sites.name').toUpperCase()}} </th>
                    <th scope="col"> DATE/TIME CREATED </th>
                </tr>
            </thead>

            <!-- loading -->
            <td colspan="2" v-if="loading" class="parent-div">
                <div class="spinner-border center-div" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </td>

            <tbody v-else>
                <tr v-show="foodList.length < 1">
                    <td colspan="3" class="no-data-container"> No data to show </td>
                </tr>
                <tr v-for="list in foodList" :key="list.id" style="vertical-align: middle;" class="cur_pointer" @click="showFoodTemp(list)">
                    <td> {{list.id}} </td>
                    <td> {{list.name}} </td>
                    <td> {{list.datetime}} </td>
                </tr>
            </tbody>
        </table>

        <pagination v-show="!loading && pagination.total > 1" :config="pagination" @search="getProductList"></pagination>
    </div>

    <food-temperature v-if="productSelected" :product="selectedProduct" @hideFoodTemperature="() => {productSelected = !productSelected}"></food-temperature>


</template>

<script>
import temperatureService from '../../../../../../../services/temperature.service';
import Pagination from '../../../../../../../components/Pagination.vue';
import FoodTemperature from './FoodTemperature.vue';
import { DateTime } from 'luxon';

export default {
    name: 'HotFood',
    props: {
        site_id: Number,
        name: String
    },
    components: {
        Pagination,
        FoodTemperature
    },
    data() {
        return {
            loading: true,
            foodList: [],
            foodType: 'hot-food',
            productSelected: false,
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            },
            selectedProduct: {},
            filters: {
                start_date: null,
                end_date: null
            }
        }
    },
    mounted() {
        this.getProductList();
    },
    methods: {
        getProductList(page = 1) {
            this.loading = true;
            temperatureService.getDailyProducts(this.site_id, this.foodType, page)
            .then(response => {
                response.data.data.forEach((item) => {
                    item.datetime = DateTime.fromSeconds(item.datetime).toFormat('dd LLL yyyy HH:mm:ss');
                });
                this.foodList = response.data.data;
                this.pagination = response.data.pagination;
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
            }) 
        },
        showFoodTemp(product) {
            this.selectedProduct = product;
            this.productSelected = true;
        }
    }
}
</script>

<style>

</style>