<template>
    <div class="row p-h-md p-v-md">
        <div class="flex-center" style="justify-content: flex-end">
            <!-- <button class="btn m-r-md" @change="selectAll" id="select-all-btn"> {{$t('sites.data.select_all')}} </button> -->
            <button class="btn btn-primary" @click="() => addAssetModal.show()"> {{$t('sites.site.upload_file')}} </button>
        </div>

        <div class="row doc-sub-container">
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <p style="font-size: 16px"> {{$t('sites.site.preview') + ':'}} <span> </span> </p>
                        <p style="font-style: italic"> {{$t('sites.site.select_file')}} </p>

                        <!-- Asset image component -->
                        <AssetImage 
                            v-if="selectedAsset !== null && !isPdf" 
                            :filename="selectedAsset.filename" 
                            :site_id="selectedAsset.site_id" 
                            :id="selectedAsset.id" 
                            :endpoint="`sites/${selectedAsset.site_id}/documents/${selectedAsset.filename}`"
                        />
                        <div v-if="selectedAsset !== null && isPdf" class="flex space-between m-b-md">
                            <span v-if="showAllPages"> {{ `${pageCount} ${$t('sites.data.pages')}` }} </span>
                            <span v-else>
                                <button :disabled=" page <= 1" @click="page--" class="btn b-primary text-primary m-r-xs" style="border-radius: 50px; width: 15px;"> <i class="material-icons"> arrow_back_ios </i> </button>
                                {{ page }} / {{ pageCount }}
                                <button :disabled=" page >= pageCount" @click="page++" class="btn b-primary text-primary m-l-xs" style="border-radius: 50px; width: 15px;"> <i class="material-icons"> arrow_forward_ios </i> </button>
                            </span>
                            <div style="float: right">
                                <label class="m-t-xxs">
                                    <input type="checkbox" v-model="showAllPages">
                                    {{$t('sites.data.show_all_pages')}}
                                </label>
                            </div>
                        </div>
                        <vue-pdf-embed 
                            v-if="selectedAsset !== null && isPdf" 
                            :source="pdf_url" 
                            ref="pdfRef"
                            :page="page"
                            @rendered="handleDocumentRender"
                        />
                    </div>
                </div>
            </div>
            <div class="col-6">
                <!-- No document uploaded -->
                <div v-show="assets.length === 0" class="no-asset-container">
                    <span> {{$t('sites.data.no_document_uploaded')}} </span>
                </div>

                <!-- Uploaded documents -->
                <div v-show="assets.length > 0">
                    <div class="card" v-for="asset in assets" :key="asset.id">
                        <div class="card-body">
                            <div class="card-header-container m-b-md">
                                <div>
                                    <!-- <input type="checkbox" class="m-r-md"> -->
                                    <label class="cursor-pointer" @click="selectAsset(asset)"> {{asset.filename}} </label>
                                </div>
                                <div v-show="asset.id === selectedAsset?.id" @click="() => deleteAssetModal.show()">
                                    <i class="material-icons-outlined cursor_pointer delete_btn"> delete </i>
                                </div>
                            </div>
                            <div> 
                                <select v-model="asset.type" @change="changeEvidenceType(asset)" class="form-select">
                                    <option v-for="type in evidenceType" :value="type.id" :key="type.id"> {{ type.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add Asset Modal -->
            <div class="modal fade" id="addAssetModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content p-h-xxl p-v-xxl">
                        <div class="modal-title flex-center">
                            <h3 class="m-r-sm"> {{$t('sites.data.add_asset_to_site').toUpperCase()}} </h3>
                        </div>
                        <p class="text-danger" style="font-weight: 700; font-size: 15px;"> {{$t('sites.data.add_asset_message')}} </p>
                        <div class="input-container m-b-sm">
                            <label class="custom-file-label"> {{file_name}} </label>
                            <input type="file" class="custom-file-input" accept="image/*,video/*,audio/*,application/pdf,application/msword,application/zip" @change="selectFile">
                        </div>
                        <div> 
                            <label class="form-label m-b-xxs"> {{$t('sites.data.asset_type')}} <span class="text-danger">*</span> </label>
                            <select v-model="newAsset.type" class="form-select m-b-sm">
                                <!-- Add a placeholder for dropdown -->
                                <!-- <option value="" selected> Select a type </option> -->
                                <option v-for="type in evidenceType" :value="type.id" :key="type.id"> {{ type.name }}</option>
                            </select>
                        </div>
                        <div class="flex-center">
                            <button class="btn btn-secondary m-r-md" @click="() => addAssetModal.hide()"> {{$t('global.cancel')}} </button>
                            <button class="btn btn-primary" @click="uploadFile" :class="{'disabled' : (newAsset.type === '' || newAsset.file === null)}"> {{$t('sites.data.add_asset')}} </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Delete Asset Modal -->
            <div class="modal fade" id="deleteAssetModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content p-h-xxl p-v-xxl">
                        <div class="modal-title flex-center">
                            <h3 class="m-r-sm"> {{$t('blacklist.are_you_sure').toUpperCase()}} </h3>
                            <i class="material-icons"> error_outline </i>
                        </div>
                        <p class="text-danger" style="font-weight: 700; font-size: 15px; text-align: center;"> {{$t('sites.data.delete_asset_message').toUpperCase()}} </p>
                        <div class="flex-center">
                            <button class="btn btn-secondary m-r-md" @click="() => deleteAssetModal.hide()"> {{$t('global.cancel')}} </button>
                            <button class="btn btn-primary" @click="deleteAsset"> {{$t('blacklist.remove_it')}} </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import AssetImage from '../../../../components/AssetImage.vue';
import SiteService from '../../../../services/site.service';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
    name: 'Documents',
    components: {AssetImage, VuePdfEmbed},
    props: {
        site: Object,
        client_id: Number
    },
    data() {
        return {
            loading: true,
            assets: this.$props.site.assets,
            evidenceType: [
                {
                    id: "petroleum_cert",
                    name: "Petroleum Certificate"
                },
                {
                    id: "site_schedule",
                    name: "Site Schedule"
                },
                {
                    id: "dvla_auth",
                    name: "DVLA Authentication"
                },
                {
                    id: "signage_map",
                    name: "Parking Signange Map"
                },
                {
                    id: "parking_contract",
                    name: "Parking Contract"
                }
            ],
            selectedAsset: null,
            pdf_url: null,
            page: 1,
            pageCount: 1,
            showAllPages: false,
            deleteAssetModal: null,
            addAssetModal: null,
            newAsset: {
                file: null,
                type: '',
                loading: true
            }
        }
    },
    mounted() {
        this.deleteAssetModal = new bootstrap.Modal(document.getElementById('deleteAssetModal'));
        this.addAssetModal = new bootstrap.Modal(document.getElementById('addAssetModal'));
    },
    methods: {
        changeEvidenceType(asset) {   
           SiteService.updateEvidenceType(asset.site_id, asset.id, asset.type)
            .then(() => {
                this.$success('Successfully updated evidence type.');
            })
            .catch(error => {
               console.log(error);
            })
        },
        selectAsset(asset) {
           this.selectedAsset = asset;
           this.downloadPDF();
        },
        selectAll() {
            
        },
        downloadPDF() {
            if(!this.isPdf){
               this.pdf_url = ""
               return;
            }
           SiteService.getPDFAsset(this.$props.site.id, this.selectedAsset.filename)
            .then(response => {
                this.pdf_url = window.URL.createObjectURL(response.data);
            })
            .catch(error => {
               console.log(error);
               this.pdf_url = "";
            })
        },
        uploadFile() {
            this.newAsset.loading = true;
            const data = new FormData();
            data.append('file', this.newAsset.file, this.newAsset.file.name);
            data.append('type', this.newAsset.type);
            SiteService.uploadFile(this.$props.site.id, data)
            .then(response => {
                this.assets.push(response.data.asset);
                this.$success(this.$t('sites.site.asset_upload_success'));
                this.newAsset.loading = false;
                this.newAsset.file = null;
                this.newAsset.type = '';
                this.addAssetModal.hide();
            })
            .catch(error => {
                this.$error(this.$t('sites.data.asset_upload_fail'), error);
            })
        },
        deleteAsset() {
            if(this.selectedAsset === null) return;
            SiteService.updateEvidenceType(this.$props.site.id, this.selectedAsset.id, 'SOFTDELETED')
            .then(response => {
                console.log(response);
                const updatedList = this.assets.filter(asset => asset.id !== this.selectedAsset.id);
                this.assets = updatedList;
                this.selectedAsset = null;
                this.$success(this.$t('claims.evidences.update_success'));
                this.deleteAssetModal.hide();
            })
            .catch(error => {
                this.$error(this.$t('claims.evidences.update_failed'), error);
            })
        },
        handleDocumentRender() {
            this.pageCount = this.$refs.pdfRef.pageCount;
        },
        selectFile(event) {
            this.newAsset.file = event.target.files[0];
        }
    },
    computed: {
        currentFileType: function() {
            if(this.selectedAsset) {
                return this.selectedAsset.filename.split(/[#?]/)[0].split('.').pop().trim();
            }
            return ''
        },
        isPdf: function() {
            return this.currentFileType === 'pdf' ? true : false; 
        },
        file_name: function() {
            if(this.newAsset.file == null){
                return "Select File";
            }
            if(this.newAsset.file.name == null){
                return "File Selected";
            }
            return this.newAsset.file.name;
        }
    },
    watch: {
        showAllPages: function() {
            this.page = this.showAllPages ? null : 1
        }
    }
}
</script>

<style scoped>
    .doc-sub-container {
        margin: auto;
        margin-top: 30px;
    }

    .card-header-container {
        display: flex;
        justify-content: space-between;
        padding: 0;
        font-weight: 100;
    }

    .no-asset-container {
        position: relative;
        top: 50%;
        left: 50%;
        font-style: italic;
        font-size: 16px;
    }

    .card-header-container input[type="checkbox"]:not(:checked),  input[type="checkbox"]:checked{
        width: 17px;
        height: 17px;
    }

    .cursor_pointer {
        cursor: pointer;
    }

    .vue-pdf-embed > div {
        margin-bottom: 8px;
        box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }

    .input-container {
        position: relative;
    }

    #deleteAssetModal .modal-content {
        border-left: 20px solid var(--utility-red);
    }

    .modal-title i {
        font-size: 37px;
        margin-bottom: 8px;
    }

    .delete_btn:hover {
        color: var(--utility-red);
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 700px;
            margin: 1.75rem auto;
        }
    }
</style>